import { apiClient } from "./httpClient";
import { getRequest } from "./httpClient";

const END_POINT = "ecm";

const getApplied = async (appliedMeasureId) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT}/applied`, { params: { appliedMeasureId } }).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response.data = "";
  }
  return { data: response.data, error: errorResponse };
};

const getNewApplied = async (definitionId) => {
  const response = await apiClient.get(`${END_POINT}/applied/new/${definitionId}`);
  return response;
};

const updateApplied = async (appliedData) => {
  const response = await apiClient.put(`${END_POINT}/applied/${appliedData.guid}`, { ...appliedData });
  return response;
};

const deleteApplied = async (appliedMeasureId) => {
  const response = await apiClient.delete(`${END_POINT}/applied/${appliedMeasureId}`);
  return response;
};

const getAppliedByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getAppliedFilterOptions = async (token, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/autoComplete/${fieldName}`, { params: { text: text } });
  return response.data;
};

const getBuildingApplied = async (addressId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(
    `${END_POINT}/applied/building/${addressId}`,
    { filterData: filterData },
    { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } }
  );
  return response;
};

const getBuildingAppliedFilterOptions = async (addressId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/building/${addressId}/autoComplete/${fieldName}`, { params: { text: text } });
  return response.data;
};

const getAppliedStatusses = async (token) => {
  const response = await apiClient.get(`${END_POINT}/${token}/ecmAppliedStatusses`);
  return response.data;
};

const getAllAppliedStatusses = async () => {
  const response = await apiClient.get(`${END_POINT}/applied/statusses`);
  return response.data;
};

const getBuildingAppliedStatusses = async (addressId, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/building/${addressId}/ecmAppliedStatusses`, { params: { text: text } });
  return response.data;
};

const getAppliedLogByToken = async (token, currentPage, pageSize, sortBy, sortDesc) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/logs/list`, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } });
  return response;
};

const getAppliedLogFilterOptions = async (token, fieldName, text) => {
  const response = await apiClient.get(`${END_POINT}/applied/${token}/logs/autoComplete/${fieldName}`, { params: { text: text } });
  return response.data;
};

const getDefinitionOverview = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getDefinitionFilterOptions = async (fieldName, text) => {
  return await getRequest(`${END_POINT}/overview/autoComplete/${fieldName}`, { text: text });
};

const getDefinition = async (definitionId) => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/definition/${definitionId}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getNlSfbCodes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/nlsfbcodes`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getCategories = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/categories`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getKinds = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/kinds`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getConditionTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/conditionTypes`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getStatusSourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/statusSourceTypes`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchDefinition = async (id, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT}/definition/${id}`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    errorResponse = apiError.toString();
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const addDefinition = async (data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.post(`${END_POINT}/definition`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    errorResponse = apiError.toString();
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

export {
  getNlSfbCodes,
  getCategories,
  getKinds,
  getConditionTypes,
  getDefinitionOverview,
  getDefinition,
  addDefinition,
  patchDefinition,
  getNewApplied,
  getApplied,
  updateApplied,
  deleteApplied,
  getDefinitionFilterOptions,
  getAppliedByToken,
  getAppliedFilterOptions,
  getBuildingApplied,
  getBuildingAppliedFilterOptions,
  getBuildingAppliedStatusses,
  getAppliedStatusses,
  getAllAppliedStatusses,
  getAppliedLogFilterOptions,
  getAppliedLogByToken,
  getStatusSourceTypes,
};
