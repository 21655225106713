import Vue from "vue";
import VueRouter from "vue-router";
import AccountWelcome from "@/views/AccountWelcome.vue";
import HomeDashboard from "@/views/HomeDashboard.vue";
import LoginUnauthorized from "@/views/basic/LoginUnauthorized.vue";
import LoginCallback from "@/views/basic/LoginCallback.vue";
import HomeBase from "@/views/HomeBase.vue";
import LocationCatalog from "@/views/project/LocationCatalog.vue";
import PartyCatalog from "@/views/project/PartyCatalog.vue";
import MeasureCatalog from "@/views/project/MeasureCatalog.vue";
import { Permission } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { routesIam } from "@/router/indexIam";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
    component: HomeBase,
    meta: {},
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: HomeDashboard,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "/admin/masterdata",
        component: () => import("@/views/admin/MasterData.vue"),
        meta: {
          authorize: [Permission.Admin],
        },
        children: [
          {
            path: "",
            name: "MD-Dashboard",
            component: () => import("@/components/masterdata/MasterdataDashboard.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addressableobjecttype",
            name: "MD-AddressableObjectType",
            component: () => import("@/components/masterdata/AddressableObjectType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addressableobjectsubtypehor",
            name: "MD-AddressableObjectSubTypeHor",
            component: () => import("@/components/masterdata/AddressableObjectSubTypeHor.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addressableobjectsubtypever",
            name: "MD-AddressableObjectSubTypeVer",
            component: () => import("@/components/masterdata/AddressableObjectSubTypeVer.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addressableobjectrooftype",
            name: "MD-AddressableObjectRoofType",
            component: () => import("@/components/masterdata/AddressableObjectRoofType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addresssourcetype",
            name: "MD-AddressSourceType",
            component: () => import("@/components/masterdata/AddressSourceType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "addresstype",
            name: "MD-AddressType",
            component: () => import("@/components/masterdata/AddressType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "constructionboundarytype",
            name: "MD-ConstructionBoundaryType",
            component: () => import("@/components/masterdata/ConstructionBoundaryType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "constructionsourcetype",
            name: "MD-ConstructionSourceType",
            component: () => import("@/components/masterdata/ConstructionSourceType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ConstructionKind",
            name: "MD-ConstructionKind",
            component: () => import("@/components/masterdata/ConstructionKind.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },        
          {
            path: "constructioninsulationcategory",
            name: "MD-ConstructionInsulationCategory",
            component: () => import("@/components/masterdata/ConstructionInsulationCategory.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eedtype",
            name: "MD-EedType",
            component: () => import("@/components/masterdata/EedType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationtype",
            name: "MD-InstallationType",
            component: () => import("@/components/masterdata/InstallationType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationproducttype",
            name: "MD-InstallationProductType",
            component: () => import("@/components/masterdata/InstallationProductType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationcontroltype",
            name: "MD-InstallationControlType",
            component: () => import("@/components/masterdata/InstallationControlType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "Installationcollaborationtype",
            name: "MD-InstallationCollaborationType",
            component: () => import("@/components/masterdata/InstallationCollaborationType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationdetailtype",
            name: "MD-InstallationDetailType",
            component: () => import("@/components/masterdata/InstallationDetailType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationsubdetailtype",
            name: "MD-InstallationSubDetailType",
            component: () => import("@/components/masterdata/InstallationSubDetailType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationinputsource",
            name: "MD-InstallationInputSource",
            component: () => import("@/components/masterdata/InstallationInputSource.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "installationdeliverytype",
            name: "MD-InstallationDeliveryType",
            component: () => import("@/components/masterdata/InstallationDeliveryType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "InstallationGroup",
            name: "MD-InstallationGroup",
            component: () => import("@/components/masterdata/InstallationGroup.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },  
          {
            path: "locationtype",
            name: "MD-LocationType",
            component: () => import("@/components/masterdata/LocationType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "StartingPointAmbition",
            name: "MD-StartingPointAmbition",
            component: () => import("@/components/masterdata/StartingPointAmbition.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "StartingPointCondition",
            name: "MD-StartingPointCondition",
            component: () => import("@/components/masterdata/StartingPointCondition.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "StartingPointStrategy",
            name: "MD-StartingPointStrategy",
            component: () => import("@/components/masterdata/StartingPointStrategy.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "nta8800function",
            name: "MD-Nta8800Function",
            component: () => import("@/components/masterdata/Nta8800Function.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "partyrole",
            name: "MD-PartyRole",
            component: () => import("@/components/masterdata/PartyRole.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "PartySourceType",
            name: "MD-PartySourceType",
            component: () => import("@/components/masterdata/PartySourceType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "PartyType",
            name: "MD-PartyType",
            component: () => import("@/components/masterdata/PartyType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/ConditionType",
            name: "MD-Ecm-ConditionType",
            component: () => import("@/components/masterdata/ecm/ConditionType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/AppliedGroup",
            name: "MD-Ecm-AppliedGroup",
            component: () => import("@/components/masterdata/ecm/EcmAppliedGroup.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/appliedstatus",
            name: "MD-Ecm-AppliedStatus",
            component: () => import("@/components/masterdata/ecm/AppliedStatus.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/buildingfunction",
            name: "MD-Ecm-BuildingFunction",
            component: () => import("@/components/masterdata/ecm/BuildingFunction.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/costsourcetype",
            name: "MD-Ecm-CostSourceType",
            component: () => import("@/components/masterdata/ecm/CostSourceType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/imcategory",
            name: "MD-Ecm-ImCategory",
            component: () => import("@/components/masterdata/ecm/ImCategory.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/kind",
            name: "MD-Ecm-Kind",
            component: () => import("@/components/masterdata/ecm/EcmKind.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/nlsfbcategory",
            name: "MD-Ecm-NlsfbCategory",
            component: () => import("@/components/masterdata/ecm/NlSfbCategory.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/nlsfbcode",
            name: "MD-Ecm-NlSfbCode",
            component: () => import("@/components/masterdata/ecm/NlSfbCode.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "ecm/statussourcetype",
            name: "MD-Ecm-StatusSourceType",
            component: () => import("@/components/masterdata/ecm/StatusSourceType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eu/energytype",
            name: "MD-EU-EnergyType",
            component: () => import("@/components/masterdata/eu/EnergyType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eu/EdsObjectType",
            name: "MD-EU-EdsObjectType",
            component: () => import("@/components/masterdata/eu/EdsObjectType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eu/EuPrecisionTypes",
            name: "MD-EU-EuPrecisionTypes",
            component: () => import("@/components/masterdata/eu/EuPrecisionTypes.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eu/registertype",
            name: "MD-EU-RegisterType",
            component: () => import("@/components/masterdata/eu/RegisterType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "eu/unittype",
            name: "MD-EU-UnitType",
            component: () => import("@/components/masterdata/eu/UnitType.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "weij/decree",
            name: "MD-Weii-Decree",
            component: () => import("@/components/masterdata/weij/WeijDecree.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: "weij/category",
            name: "MD-Weii-Category",
            component: () => import("@/components/masterdata/weij/WeijCategory.vue"),
            meta: {
              authorize: [Permission.Admin],
            },
          },
        ],
      },
      {
        path: "/locations",
        name: "Locations",
        component: LocationCatalog,
        meta: {
          authorize: [Permission.Admin],
        },
      },

      {
        path: "location",
        component: () => import("@/views/project/location/LocationBase.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
        children: [
          {
            path: "add",
            name: "LocationAdd",
            component: () => import("@/views/project/location/LocationDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Locations",
            },
          },
          {
            path: "add-wizard",
            name: "LocationAddWizard",
            component: () => import("@/views/project/location/wizard/AddLocation.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Locations",
            },
          },
          {
            path: ":locationReference",
            name: "Location",
            component: () => import("@/views/project/location/LocationSummary.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/details",
            name: "LocationDetails",
            component: () => import("@/views/project/location/LocationDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/addressdetails/add",
            name: "AddressDetailsAdd",
            component: () => import("@/views/project/location/AddressDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/sublocation/add",
            name: "SublocationAdd",
            component: () => import("@/views/project/location/LocationSub.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/sublocation/:id",
            name: "SublocationEdit",
            component: () => import("@/views/project/location/LocationSub.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/addressdetails/:id",
            name: "AddressDetails",
            component: () => import("@/views/project/location/AddressDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/ConstructionDetails/add",
            name: "ConstructionDetailsAdd",
            component: () => import("@/views/project/location/ConstructionDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/constructionDetails/:id",
            name: "ConstructionDetails",
            component: () => import("@/views/project/location/ConstructionDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/installationDetails/add",
            name: "InstallationDetailsAdd",
            component: () => import("@/views/project/location/InstallationDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/installationDetails/:id",
            name: "InstallationDetails",
            component: () => import("@/views/project/location/InstallationDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/locationregister/add",
            name: "LocationRegisterDetailsAdd",
            component: () => import("@/views/project/location/LocationRegisterDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/locationnewregister/add",
            name: "LocationNewRegisterDetailsAdd",
            component: () => import("@/views/project/location/LocationNewRegisterDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/locationregister/:id/details",
            name: "LocationRegisterDetails",
            component: () => import("@/views/project/location/LocationRegisterDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/energylabel/add",
            name: "EnergyLabelAdd",
            component: () => import("@/views/project/location/EnergyLabelDetail.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/energylabel/:id",
            name: "EnergyLabelDetail",
            component: () => import("@/views/project/location/EnergyLabelDetail.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":locationReference/partyaddress/add",
            name: "PartyAddressDetailsAdd",
            component: () => import("@/views/project/location/PartyAddressDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Location",
            },
          },
          {
            path: ":locationReference/partyaddress/:id/details",
            name: "PartyAddressDetails",
            component: () => import("@/views/project/location/PartyAddressDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
        ],
      },

      {
        path: "/parties",
        name: "Parties",
        component: PartyCatalog,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "party",
        component: () => import("@/views/project/PartyBase.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
        children: [
          {
            path: "add",
            name: "PartyAdd",
            component: () => import("@/views/project/PartyDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "Parties",
            },
          },
          {
            path: ":partyReference",
            name: "Party",
            component: () => import("@/views/project/PartySummary.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":partyReference/details",
            name: "PartyDetails",
            component: () => import("@/views/project/PartyDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":partyReference/powerbi",
            name: "Rapportage",
            component: () => import("@/views/project/PowerBISelector.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
          {
            path: ":partyReference/powerbi/reports/:id?",
            name: "PowerBIReportDetails",
            component: () => import("@/components/party/PowerBIReportDetails.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
            },
          },
        ],
      },

      {
        path: "/energydata",
        name: "EU",
        component: () => import("@/views/energydata/EnergyDataBase.vue"),
        props: true,
        meta: {
          authorize: [Permission.UpdateEnergyUsage],
        },
        children: [],
      },
      {
        path: "/energydata/registers",
        name: "EU-Registers",
        component: () => import("@/views/energydata/EnergyDataRegisters.vue"),
        props: true,
        meta: {
          authorize: [Permission.UpdateEnergyUsage],
        },
      },
      {
        path: "/energydata/overview",
        name: "EU-Base",
        component: () => import("@/views/energydata/EnergyDataBase.vue"),
        props: true,
        meta: {
          authorize: [Permission.UpdateEnergyUsage],
        },
        children: [],
      },

      {
        path: "/energydata/register",
        name: "EU-RegisterBase",
        component: () => import("@/views/energydata/EnergyDataRegisterBase.vue"),
        props: true,
        meta: {
          authorize: [Permission.UpdateEnergyUsage],
        },
        children: [
          {
            path: "add",
            name: "EU-RegisterAdd",
            component: () => import("@/views/energydata/EnergyDataRegister.vue"),
            props: true,
            meta: {
              authorize: [Permission.Admin],
              withoutAddmodus: "EU-Register",
            },
          },
          {
            path: ":registerId",
            name: "EU-Register",
            component: () => import("@/views/energydata/EnergyDataRegister.vue"),
            props: true,
            meta: {
              authorize: [Permission.UpdateEnergyUsage],
            },
          },
        ],
      },
      {
        path: "/measures",
        name: "Measures",
        component: MeasureCatalog,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "/measure/add",
        name: "MeasureDetailsAdd",
        component: () => import("@/views/project/MeasureDetail.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "Measures",
        },
      },
      {
        path: "/measure/:id",
        name: "MeasureDetails",
        component: () => import("@/views/project/MeasureDetail.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      ...routesIam,
    ],
  },
  {
    path: "/admin",
    name: "Administration",
    component: HomeBase,
    meta: {
      authorize: ["CLLAdmin"],
    },
    children: [
      {
        path: "/admin/import",
        name: "ImportData",
        component: () => import("@/views/admin/ImportData.vue"),
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "/admin/importvabi",
        name: "ImportVabiData",
        component: () => import("@/views/admin/ImportVabi.vue"),
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "/admin/info",
        name: "Info",
        component: () => import("@/views/admin/ApplicationInfo.vue"),
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: AccountWelcome,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/callback",
    name: "Callback",
    component: LoginCallback,
    meta: {
      public: true,
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: LoginUnauthorized,
  },
];

const router = new VueRouter({
  routes,
  mode: "history", // haalt # weg uit url
});

router.beforeEach(async (to, from, next) => {
  console.log("beforeEach to  ", to);
  // console.log("beforeEach from", from);
  // console.log("beforeEach next", next);
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
  const authStore = useAuthStore();
  const loggedIn = await authStore.isLoggedIn();
  const { authorize } = to.meta;

  //console.log("beforeEach: ", to, from, next);
  //console.log(`beforeEach: isPublic: ${isPublic}, onlyWhenLoggedOut: ${onlyWhenLoggedOut}, loggedIn: ${loggedIn}, currentUser: `, currentUser);
  if (!isPublic && !loggedIn) {
    if (to.name == "Dashboard") {
      return next({ name: "Account" });
    } else {
      return next({
        name: "Account",
        params: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
      });
    }
  } else {
    //console.log("beforeEach authorize: ", authorize);
    if (authorize) {
      console.log("authorize");
      if (!loggedIn) {
        // not logged in so redirect to login page with the return url
        return next({ name: "Account", params: { returnUrl: to.path } });
      }

      const userStore = useUserStore();
      // check if route is restricted by role
      const authorised = await userStore.isAuthorized(authorize);

      // check if route is restricted by role
      if (authorize.length && !authorised) {
        // Indien men voor een pagina niet geautoriseerd is wordt geredirect naar de Dashboardpagina.
        // Als men ook daar niet voor geautoriseerd is wordt pas geredirect naar /unauthorized.
        if (to.name == "Dashboard") {
          router.app.$toaster.error("U bent niet geautoriseerd voor deze pagina.");
          return next({ name: "Unauthorized" });
        } else {
          return next({ name: "Dashboard" });
        }
      }
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    // console.log('beforeEach onlyWhenLoggedOut');
    return next({ name: "Dashboard" });
  }

  //als de route een add is (via parameter addModus) deze ook een meta tag withoutAddmodus moeten hebben
  //als de route dus een withoutAddmodus heeft maar niet de parameter addModus
  //wat kan komen doordat niet de link direct is aangeklikt maar next of refresh in de browser
  //wordt de withoutAddmodus gebruikt om de pagina niet naar de add te sturen maar naar een alternatieve pagina bv de overview
  if (to.meta.withoutAddmodus && to.params.addModus !== true) {
    console.log("beforeEach withoutAddmodus ", to.name, " => ", to.meta.withoutAddmodus);
    return next({ name: to.meta.withoutAddmodus });
  }

  document.title = router.app.$appConfig.title;

  //console.log("beforeEach ok");
  next();
});

export default router;
