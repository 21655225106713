<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { getSummaryLocations, getSummaryParties, getSummaryEcmDefinitions, getSummaryRegisters } from "@/services/api/report.api";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const locationCount = ref();
const partyCount = ref();
const ecmDefinitionCount = ref();
const registerCount = ref();

onMounted(() => {
  getDataLocations();
  getDataParties();
  getDataEcmDefinitions();
  getDataRegisters();
});

const welcomeName = computed(() => {
  const name = userStore.friendlyName;
  return name.length > 0 ? ` ${name}` : "";
});

const allowWelcome = computed(() => userStore.hasPermissionWelcome);

async function getDataLocations() {
  const response = await getSummaryLocations();
  if (response && !response.error) {
    locationCount.value = response.data.count.toString();
  }
}

async function getDataParties() {
  const response = await getSummaryParties();
  if (response && !response.error) {
    partyCount.value = response.data.count.toString();
  }
}

async function getDataEcmDefinitions() {
  const response = await getSummaryEcmDefinitions();
  if (response && !response.error) {
    ecmDefinitionCount.value = response.data.count.toString();
  }
}

async function getDataRegisters() {
  const response = await getSummaryRegisters();
  if (response && !response.error) {
    registerCount.value = response.data.count.toString();
  }
}
</script>
<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12" class="app-dashboard-overview">
        <v-container fluid py-0>
          <header class="app-welcome-message">
            <h1>Welkom{{ welcomeName }},</h1>
            Deze applicatie beheert de pandenlijst.<br /><br />
          </header>
          <v-row>
            <v-col>
              Er zijn <span :class="{ loadingDots: !locationCount }">{{ locationCount }}</span> locaties aanwezig. Om naar het overzicht te gaan, klik
              <router-link :to="{ name: 'Locations' }" class="widget-link">hier</router-link>.<br />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Er zijn <span :class="{ loadingDots: !partyCount }">{{ partyCount }}</span> partijen aanwezig. Om naar het overzicht te gaan, klik
              <router-link :to="{ name: 'Parties' }" class="widget-link">hier</router-link>.<br />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Er zijn <span :class="{ loadingDots: !ecmDefinitionCount }">{{ ecmDefinitionCount }}</span> maatregelen aanwezig. Om naar het overzicht te gaan, klik
              <router-link :to="{ name: 'Measures' }" class="widget-link">hier</router-link>.<br />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Er zijn <span :class="{ loadingDots: !registerCount }">{{ registerCount }}</span> meters aanwezig. Om naar het overzicht te gaan, klik
              <router-link :to="{ name: 'EU-Registers' }" class="widget-link">hier</router-link>.<br />
            </v-col>
          </v-row>
          <v-row v-if="allowWelcome" class="mt-15">
            <v-col cols="auto"> Welkom bij de pandenlijst! </v-col>
            <v-col cols="auto">
              <v-alert type="success">Dit bericht wordt getoond met het recht 'Welcome'!</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
