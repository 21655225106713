import { getRequest, postRequest, patchRequest, deleteRequest } from "./httpClient";

const END_POINT = "powerbi";

const getEmbeddedReport = async (workspaceId, reportId) => {
    return getRequest(`${END_POINT}/embed`, { workspaceId: workspaceId, reportId: reportId });
};

const getReportDetails = async (reportId) => {
	return getRequest(`${END_POINT}/${reportId}/detail`);
}

const addReport = async reportData => {
	return postRequest(`${END_POINT}`, { ...reportData });
};

const updateReport = async (reportId, reportData) => {
	return patchRequest(`${END_POINT}/${reportId}`, { ...reportData });
};

const deleteReport = async reportId => {
	return deleteRequest(`${END_POINT}/${reportId}`);
};

export {
	getEmbeddedReport,
	getReportDetails,
	addReport,
	updateReport,
	deleteReport
}
