import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import vuetify from "@/plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.config.productionTip = false;
Vue.prototype.$showMenu = false;

/**
 * Vendor
 */
//todo wordt niet gebruikt??
//import axios from "axios";
//Vue.prototype.$http = axios;

import lodashClonedeep from "lodash.clonedeep";
Vue.prototype.Clonedeep = lodashClonedeep;

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import lodash from "lodash";
Vue.prototype._ = lodash;

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
Vue.component("vueDropzone", vue2Dropzone);

import Vue2Filters from "vue2-filters"; // https://www.npmjs.com/package/vue2-filters

import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";

import VueNumber from "vue-number-animation";

import "@/styles/app.scss";

import * as VeeValidate from "vee-validate";
import "@/mixins/vee-validate";

import VueMasonry from "vue-masonry-css";

/**
 * General
 *
 */

import { useAuthStore } from "@/stores/auth";
import httpClient from "@/services/api/httpClient";
import httpClientUnauthorized from "@/services/api/httpClientUnauthorized";

Vue.component("information-dropdown", require("@/components/general/InformationDropdown").default);
Vue.component("information-modal", require("@/components/general/InformationModal").default);

// Vue.component('year-slider', require('@/components/general/YearSlider').default);
Vue.component("loader-spinner", require("@/components/general/LoaderSpinner").default);

Vue.component("filter-set", require("@/components/general/filters/FilterSet").default);
Vue.component("filter-item", require("@/components/general/filters/FilterItem").default);

Vue.component("location-map", require("@/components/general/LocationMap").default);

/**
 * General
 */
Vue.component("details-buttons", require("@/components/general/DetailsButtons").default);

Vue.component("inn-breadcrumbs", require("@/components/general/InnBreadcrumbs").default);

/**
 * Location
 */
Vue.component("location-catalog", require("@/views/project/LocationCatalog").default);

/**
 * Party
 */
Vue.component("party-catalog", require("@/views/project/PartyCatalog").default);
Vue.component("powerbi-viewer", require("@/components/party/PowerBIViewer").default);
/**
 * Measures
 */
Vue.component("measure-catalog", require("@/views/project/MeasureCatalog").default);

/**
 * Users
 */

/**
 * Admin
 */

/*
 * Config en Vue start
 */

const hostUrl = window.location.origin + "/";
//normaal staat portal in de root maar voor dev is dat anders daarom wordt vanuit env bepaald
const portalUrl = process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : hostUrl;
console.log("%cConfig starting ", "background: green; color: yellow", portalUrl);

import { getConfiguration } from "@/stores/appconfig";

getConfiguration(hostUrl, portalUrl).then((appConfig) => {
  Vue.prototype.$appConfig = {
    title: appConfig.title,
    hostUrl: hostUrl,
    portalUrl: portalUrl,
    identityUrl: appConfig.identityUrl,
  };
  console.log("%cConfig loaded ", "background: green; color: yellow", JSON.stringify(Vue.prototype.$appConfig));

  Vue.use(PerfectScrollbar, { options: { wheelPropagation: false } });
  Vue.use(lodash);
  Vue.use(Vue2Filters);
  Vue.use(Toaster, { timeout: 5000 });
  Vue.use(VueNumber);
  Vue.use(VeeValidate);
  Vue.use(VueMasonry);

  Vue.use(PiniaVuePlugin);
  const pinia = createPinia();
  Vue.use(pinia);

  const authStore = useAuthStore();
  authStore.init(appConfig);

  Vue.use(httpClient, { portalUrl: appConfig.portalUrl });
  Vue.use(httpClientUnauthorized, { portalUrl: appConfig.portalUrl });

  new Vue({
    router,
    vuetify,
    lodash,
    render: (h) => h(App),
  }).$mount("#app");
});
