<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  isLoading: Boolean,

  minHeight: Number,
});

const loaderStyles = computed(() => {
  if (props.minHeight) {
    return {
      minHeight: `${props.minHeight}px`,
    };
  }
  return {};
});
</script>

<template>
  <v-container class="overview-loader" :style="loaderStyles" fill-height>
    <v-row>
      <v-col>
        <span class="icon-loader-icon" :class="{ spinner: props.isLoading }"></span>
      </v-col>
    </v-row>
  </v-container>
</template>
