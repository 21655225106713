import { ref } from "vue";
import _ from "lodash";

export default function useGrid27(callbacks) {
  const gridData = ref([]);
  const gridOptions = ref({});
  const gridSorting = ref({ by: undefined, desc: false });
  const gridIsLoading = ref(true);

  const pageNumber = ref(0);
  const pageSize = ref(10);
  const pageCount = ref(0);
  const totalItems = ref(0);

  const fetchCount = ref(0);

  function changedOptions(message) {
    console.log("%cGrid27, changedOptions", "background: yellow; color: black", JSON.stringify(message));
    const newSortBy = gridOptions.value.sortBy && gridOptions.value.sortBy.length > 0 ? gridOptions.value.sortBy[0] : undefined;
    const newSortDesc = gridOptions.value.sortDesc && gridOptions.value.sortDesc.length > 0 ? gridOptions.value.sortDesc[0] : false;
    if (newSortBy != gridSorting.value.by || newSortDesc != gridSorting.value.desc) {
      //console.log("%cGrid27, changedOptions", "background: yellow; color: black", newSortBy, gridSorting.value.by, newSortDesc, gridSorting.value.desc);
      gridSorting.value.by = newSortBy;
      gridSorting.value.desc = newSortDesc;
      fetchFirstPage();
    }
  }

  async function fetchFirstPage() {
    await fetchData(1, pageSize.value, gridSorting.value.by, gridSorting.value.desc);
  }

  async function fetchData(fetchPageNumber, fetchPageSize, sortBy, sortDesc) {
    fetchCount.value++;
    if (callbacks.fetchDataStart) {
      callbacks.fetchDataStart({ fetchCount: fetchCount.value });
    }

    gridIsLoading.value = true;
    const response = await callbacks.getData(fetchPageNumber, fetchPageSize, sortBy, sortDesc);
    gridIsLoading.value = false;

    let loadedCount = 0;
    if (response) {
      if (!response.error && response.data) {
        totalItems.value = response.data.totalNumber;
        pageNumber.value = fetchPageNumber;
        pageSize.value = fetchPageSize;
        pageCount.value = response.data.lastPage;
        gridData.value = [...response.data.data];
        loadedCount = gridData.value.length;
      } else {
        if (callbacks.fetchDataError) {
          callbacks.fetchDataError(response.error);
        }
        //$toaster.error("Ophalen van de data is mislukt! " + response.error);
      }
    }
    //console.log("grid fetchDataComplete : ", loadedCount, totalItems.value, fetchCount.value);
    if (callbacks.fetchDataComplete) {
      callbacks.fetchDataComplete({ count: loadedCount, totalCount: totalItems.value, fetchCount: fetchCount.value });
    }
  }

  function changedPaging(paging) {
    // console.log("grid changedPaging : ", paging.pageNumber, paging.pageSize);
    fetchData(paging.pageNumber, paging.pageSize, gridSorting.value.by, gridSorting.value.desc);
  }

  function changePageSize(nePageSize) {
    pageSize.value = nePageSize;
  }

  function gridDataFromKeyDescription(data, sortBy, sortDesc) {
    let sortedData;
    if (sortBy) {
      sortedData = _.orderBy([...data.keyDescList], [sortBy], [sortDesc ? "desc" : "asc"]);
    } else {
      sortedData = _.orderBy([...data.keyDescList], ["key"], ["asc"]);
    }
    return { data: { data: sortedData, totalNumber: sortedData.length, lastPage: 0, currentPage: 1 } };
  }

  return { fetchFirstPage, gridData, gridDataFromKeyDescription, gridOptions, gridSorting, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging, changePageSize };
}
