import { apiClient, putRequest } from "./httpClient";

const END_POINT = 'userAccess';

const setCurrentUser = async () => {
    console.log("%cuser.api setCurrentUser ", "background: yellow; color: green");
    const response = await putRequest(`${END_POINT}/current`);
    return response;
}
 
const setCurrentProject = async (projectId) => {
    console.log("user.api, setCurrentProject, projectId: ", projectId)
    const response = await apiClient.put(`${END_POINT}/project/`+projectId );
    console.log("user.api, setCurrentProject, response: ", response)

    return response;
}

export {
    setCurrentUser,
    setCurrentProject
}