<script lang="ts" setup>
import { computed, watch, getCurrentInstance } from "vue";
import { getAddressesOverview, getAddressesOverviewChilds } from "@/services/api/location.api";
import { getPartyAddressesOverview } from "@/services/api/party.api";
import GridPagination from "@/components/general/GridPagination.vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 25;

const props = defineProps({
  locationReference: {
    type: String,
  },
  partyReference: {
    type: Number,
  },
  allowMutation: {
    type: Boolean,
    default: false,
  },
  showChilds: {
    type: Boolean,
    default: false,
  },
  addLink: undefined,
});

const headers = computed(() => {
  let h = [{ text: "", value: "actions", sortable: false, width: 20 }];
  if (props.showChilds) {
    h.push({ text: "Locatie", value: "locRef" });
  }
  h.push(
    { text: "Referentie", value: "ref" },
    { text: "Type", value: "typDescr" },
    { text: "Omschrijving", value: "descr" },
    { text: "Adr. Type", value: "adrTypDescr" },
    { text: "Adres", value: "compAddr" },
    { text: "Postcode", value: "pc" },
    { text: "Woonplaats", value: "city" },
    { text: "Bron", value: "srcTypDescr" },
    { text: "Begindatum", value: "datFrom" },
    { text: "Einddatum", value: "datTill" },
    { text: "Versies", value: "detailCnt", sortable: false, align: "center" }
  );
  return h;
});

watch(
  () => props.locationReference,
  () => {
    console.log("LocationAddressGrid, watch locationReference ", props.locationReference, props.partyReference);
    fetchFirstPage();
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  //console.log("%cLocationAddressGrid, getData", "background: GoldenRod; color: black", pageNumber, pageSize, JSON.stringify(gridOptions));
  //console.log("%cLocationAddressGrid, getData", "background: GoldenRod; color: black", JSON.stringify(props.filterData));
  //console.log("%cLocationAddressGrid, getData", "background: GoldenRod; color: black", props.locationReference.toString(), props.showChilds);
  const pageNumber0 = pageNumber - 1;
  let response = undefined;
  if (props.partyReference == undefined) {
    if (props.showChilds) {
      response = await getAddressesOverviewChilds(props.locationReference.toString(), pageNumber0, pageSize, sortBy, sortDesc);
    } else {
      response = await getAddressesOverview(props.locationReference.toString(), pageNumber0, pageSize, sortBy, sortDesc);
    }
  } else {
    response = await getPartyAddressesOverview(props.partyReference.toString(), props.locationReference.toString(), pageNumber0, pageSize, sortBy, sortDesc);
  }
  return response;
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="2">
        <header class="app-welcome-message">
          <slot name="header"></slot>
        </header>
      </v-col>
      <v-col v-if="allowMutation && addLink" cols="1">
        <v-btn dark class="primary" :to="addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :server-items-length="totalItems"
      :items-per-page="pageSize"
    >
      <template v-slot:[`item.datFrom`]="{ item }">
        {{ formatDateUtc(item.datFrom) }}
      </template>
      <template v-slot:[`item.datTill`]="{ item }">
        {{ formatDateUtc(item.datTill) }}
      </template>

      <template v-slot:[`item.locRef`]="{ item }">
        {{ item.locRef }}
        <v-btn v-if="showChilds" :to="{ name: 'Location', params: { locationReference: item.locRef.toString() } }" icon small plain>
          <v-icon small> mdi-link-variant </v-icon>
        </v-btn>
      </template>
      <template v-if="allowMutation" v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="showChilds" :to="{ name: 'SublocationEdit', params: { locationReference: locationReference, id: item.locationParentId, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn v-else :to="{ name: 'AddressDetails', params: { locationReference: locationReference.toString(), id: item.masterId, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <grid-pagination v-if="showChilds" :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
