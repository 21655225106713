<script lang="ts" setup>
import { ref, watch } from "vue";
import { FilterStatus, useFilterDataStore } from "@/stores/filterdata";

const emits = defineEmits(["filters-changed", "filters-changed27", "filter-search"]);

const props = defineProps({
  name: String,
  filters: {
    type: Array,
    default: () => [],
  },
  preFilters: {
    type: Array,
    default: () => [],
  },
});

const filterDataStore = useFilterDataStore();
const activeFilter = ref(-1);
const filterValues = ref([]);

// zet de zoek options van een filter
function filterOptions(index) {
  //      console.log("FilterSet, filterOptions, filterIndex: ", index);
  return [...props.filters[index].options];
}

function filterStatus(index) {
  //      console.log("FilterSet, filterStatus, filterIndex: ", index);
  return props.filters[index].status ? props.filters[index].status : FilterStatus.None;
}

async function handleFilterExpanded(index) {
  activeFilter.value = index;
}

function handleFilterActivated(payload) {
  //console.log("FilterSet, handleFilterActivated: ", payload);
  let currentFilters = filterValues.value.filter((filterValue) => filterValue.index !== payload.index);

  filterValues.value = [...currentFilters, payload];
  //console.log("FilterSet, filterValues: ", filterValues.value);
}

function handleFilterDeactivated(index) {
  //console.log("FilterSet, handleFilterDeactivated: ", index);
  filterValues.value = filterValues.value.filter((filterValue) => filterValue.index !== index);
}

// pass zoek door naar parent
async function handleFilterSearch(index, text) {
  //console.log("FilterSet handleFilterSearch: ", index, text);
  emits("filter-search", index, text);
}
watch(
  () => props.filters,
  () => {
    //zet filters terug met opgeslagen waarde
    //console.log("FilterSet watch props.filters: ", props.filters);
    const newFilterValues = [];
    for (let index = 0; index < props.filters.length; index++) {
      if (props.filters[index].show === undefined || props.filters[index].show == true) {
        //console.log("FilterSet watch props.filters: ", props.filters[index]);
        const item = filterDataStore.getFilterItemData(props.name, props.filters[index].name);
        if (item) {
          newFilterValues.push({ index: index, values: item.values && item.values.length > 0 ? [...item.values] : [], min: item.min ? item.min : undefined, max: item.max ? item.max : undefined });
        }
      }
    }
    filterValues.value = [...newFilterValues];
    //console.log("FilterSet watch props.filters filterValues: ", filterValues.value);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => filterValues.value,
  () => {
    //console.log("FilterSet watch filterValues: ", filterValues.value, props.preFilters);
    const filterData = [
      ...props.preFilters,
      ...filterValues.value.map((option) => ({
        fieldName: props.filters[option.index].fieldName,
        exact: option.values && option.values.length > 0 ? [...option.values] : [],
        min: option.min ? option.min : undefined,
        max: option.max ? option.max : undefined,
      })),
    ];
    //console.log("FilterSet watch filterValues filterData: ", filterData);
    emits("filters-changed27", filterData);
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
<template>
  <div class="vue-filter-set">
    <span v-for="(filterItem, index) in filters" v-bind:key="filterItem.fieldName">
      <filter-item
        v-if="filterItem.show === undefined || filterItem.show == true"
        :filterName="props.name"
        :type="filterItem.type"
        :name="filterItem.name"
        :filterIndex="index"
        :searchOptions="filterOptions(index)"
        :search-status="filterStatus(index)"
        :filter-set-active-filter="activeFilter"
        @filter-search="handleFilterSearch"
        @filter-expanded="handleFilterExpanded"
        @filter-activated="handleFilterActivated"
        @filter-deactivated="handleFilterDeactivated"
      />
    </span>
  </div>
</template>
