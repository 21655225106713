import { ref } from "vue";
import { defineStore } from "pinia";
import Oidc from "oidc-client";

const useAuthStore = defineStore("auth", () => {
  const mgr = ref({});

  function init(options) {
    console.log("create Oidc.UserManager ", options);

    mgr.value = new Oidc.UserManager({
      authority: options.identityUrl,
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: `${options.hostUrl}callback`,
      response_type: "code",
      scope: "openid profile " + process.env.VUE_APP_SCOPE,
      post_logout_redirect_uri: options.hostUrl,
      userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
      automaticSilentRenew: true,
      silent_redirect_uri: `${options.hostUrl}silent-renew.html`,
      accessTokenExpiringNotificationTime: 60,
    });
  }

  async function login() {
    console.log("login ", mgr.value.settings);
    mgr.value.settings;
    return await mgr.value.signinRedirect();
  }

  async function logout() {
    console.log("logout");
    const user = await mgr.value.getUser();
    return await mgr.value.signoutRedirect({ id_token_hint: user.id_token });
  }

  async function getUser() {
    //console.log('getUser');
    return await mgr.value.getUser();
  }

  async function getAccessToken() {
    // console.log('getAccessToken');
    const data = await mgr.value.getUser();
    //console.log('getAccessToken ',data.access_token);
    return data.access_token;
  }

  async function signinRedirectCallback() {
    console.log("authStore, signinRedirectCallback");
    return await mgr.value.signinRedirectCallback();
  }

  async function signinSilentCallback() {
    console.log("authStore, signinSilentCallback");
    return await mgr.value.signinSilentCallback();
  }

  async function isLoggedIn() {
    const user = await mgr.value.getUser();
    return !!user && !user.expired;
  }

  async function isAllowedForApplication() {
    const user = await mgr.value.getUser();
    //console.log("authStore, isAllowedForApplication ", user);
    return user.profile.innax_application && user.profile.innax_application.includes(process.env.VUE_APP_CLIENT_ID);
  }

  return { init, login, logout, getUser, getAccessToken, signinSilentCallback, signinRedirectCallback, isLoggedIn, isAllowedForApplication };
});

export { useAuthStore };
