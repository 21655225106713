<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getLocationOverview, getLocationOverviewFilterOptions } from "@/services/api/location.api";
import { getPartyLocationOverview, getPartyLocationOverviewFilterOptions } from "@/services/api/party.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import GridPagination from "@/components/general/GridPagination.vue";
import LocationAddress from "@/components/location/LocationAddressGrid.vue";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  partyReference: {
    type: Number,
  },
  explicitPageSize: {
    type: Number,
    default: undefined,
  },
  allowMutationAddress: {
    type: Boolean,
    default: false,
  },
});

const filterData = ref([]);

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "INNAX-ID", value: "reference" },
  { text: "Omschrijving", value: "descr" },
  { text: "Type", value: "typDescr" },
  { text: "Begindatum", value: "dateFrom" },
  { text: "Einddatum", value: "dateTill" },
  { text: "Versies", value: "detailCnt", sortable: false, align: "center" },
  {
    text: "Bovenliggende reference",
    value: "parReference",
    sortable: false,
  },
  { text: "Gewijzigd door", value: "modBy" },
];
const headers = computed(() => _headers);

const filters = ref([
  {
    name: "INNAX-ID",
    fieldName: "reference",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Adres",
    fieldName: "aod.compAddr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Postcode",
    fieldName: "aod.pc",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Woonplaats",
    fieldName: "aod.city",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

const expanded = ref([]);
const singleExpand = ref(true);

watch(
  () => props.explicitPageSize,
  () => {
    if (props.explicitPageSize) {
      pageSize.value = props.explicitPageSize;
    }
  },
  { immediate: true }
);

watch(
  () => filterData.value,
  () => {
    fetchFirstPage();
  },
  { deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  let response = undefined;
  console.log("%cLocationOverviewGrid, getData: ", "background: Gold; color: black", pageSize, sortBy, sortDesc);
  if (props.partyReference == undefined) {
    response = await getLocationOverview(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  } else {
    response = await getPartyLocationOverview(props.partyReference, pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  }
  return response;
}

async function handleFilterSearch(index, text) {
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    default:
      if (text && text.length > 0) {
        let response;
        if (props.partyReference == undefined) {
          response = await getLocationOverviewFilterOptions(filters.value[index].fieldName, text);
        } else {
          response = await getPartyLocationOverviewFilterOptions(props.partyReference, filters.value[index].fieldName, text);
        }
        console.log("LocationOverviewGrid handleFilterSearch response: ", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function handleFiltersChanged(newFilterData) {
  console.log("cLocationOverviewGrid handleFiltersChanged filterData: ", newFilterData);
  filterData.value = [...newFilterData];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.Location" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      :items="gridData"
      @update:options="changedOptions"
      hide-default-footer
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
      show-expand
      :single-expand="singleExpand"
      :expanded.sync="expanded"
    >
      <template v-slot:[`item.dateFrom`]="{ item }">
        {{ formatDateUtc(item.dateFrom) }}
      </template>
      <template v-slot:[`item.dateTill`]="{ item }">
        {{ formatDateUtc(item.dateTill) }}
      </template>
      <template v-slot:[`item.parReference`]="{ item }">
        <span v-if="item.hasMultipleParents">meerdere locaties</span>
        <span v-else-if="item.parReference">{{ item.parReference }}: {{ item.parDescr }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'Location', params: { locationReference: item.reference.toString() } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:top>
        <v-switch v-model="singleExpand" label="Een adres tegelijk uitklappen" class="mt-1"></v-switch>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <location-address class="mt-3" :colspan="headers.length" :allowMutation="allowMutationAddress" :partyReference="partyReference" :locationReference="item.reference.toString()">
            <template v-slot:header>
              <h4>Adressen</h4>
            </template>
          </location-address>
          <br />
        </td>
      </template>
    </v-data-table>

    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
