<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import debounce from "lodash.debounce";
import { useFilterDataStore, FilterStatus } from "@/stores/filterdata";

const emits = defineEmits(["filter-expanded", "filter-search", "filter-deactivated", "filter-activated"]);

const props = defineProps({
  filterName: String,
  type: String,
  name: String,
  filterIndex: {
    type: Number,
    default: null,
  },
  filterSetActiveFilter: {
    type: Number,
    default: -1,
  },

  searchOptions: { type: Array, default: () => [] },
  searchStatus: {
    type: Number,
    default: FilterStatus.None,
  },
});

const expanded = ref(false);
const query = ref(undefined);
const active = ref(false);
const checkedOptions = ref([]);
const min = ref(0);
const max = ref(100);

const nameLowerCase = computed(() => props.name.toLowerCase());

function toggle() {
  //console.log("FilterItem, watch toggle: ", expanded.value);
  expanded.value = !expanded.value;
}

function collapse() {
  expanded.value = false;
}

watch(
  () => expanded.value,
  (isExpanded) => {
    //console.log("FilterItem, watch expanded: ", nameLowerCase.value, isExpanded);
    if (isExpanded) {
      emits("filter-expanded", props.filterIndex);
    }
  }
);

watch(
  () => props.filterSetActiveFilter,
  (newIndex) => {
    //console.log("FilterItem, watch filterSetActiveFilter: ", newIndex);
    if (newIndex !== props.filterIndex) {
      collapse();
    }
  }
);

const filterDataStore = useFilterDataStore();

watch(
  () => [props.filterName, nameLowerCase.value],
  () => {
    //console.log("FilterItem, watch filterName: ", props.filterName, nameLowerCase.value);
    active.value = filterDataStore.getFilterItem(props.filterName, nameLowerCase.value, "active");
    const savedCheckedOptions = filterDataStore.getFilterItem(props.filterName, nameLowerCase.value, "checked");
    if (savedCheckedOptions) {
      checkedOptions.value = [...savedCheckedOptions];
    }
    if (props.type == "range") {
      //console.log("FilterItem, setMinMax: ");
      const savedMinmax = filterDataStore.getFilterItem(props.filterName, nameLowerCase.value, "minmax");
      //console.log("FilterItem, watch filterName savedMinmax: ", savedMinmax);
      if (savedMinmax) {
        min.value = savedMinmax.min;
        max.value = savedMinmax.max;
      } else {
        resetMinMaxToDefault();
      }
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => active.value,
  () => {
    filterDataStore.setFilterItem(props.filterName, nameLowerCase.value, "active", active.value);
  }
);

watch(
  () => checkedOptions.value,
  () => {
    //console.log("FilterItem, watch checkedOptions: ", nameLowerCase.value, checkedOptions.value);
    filterDataStore.setFilterItem(props.filterName, nameLowerCase.value, "checked", [...checkedOptions.value]);
  },
  {
    deep: true,
  }
);

watch(
  () => [min.value, max.value],
  () => {
    //console.log("FilterItem, watch min.value, max.value: ", min.value, max.value);
    filterDataStore.setFilterItem(props.filterName, nameLowerCase.value, "minmax", { min: min.value, max: max.value });
  }
);

const filteredSearchOptions = computed(() => {
  switch (props.type) {
    case "search":
      return props.searchOptions;
    case "list":
      if (query.value) {
        return props.searchOptions.filter((x) => x.text.toLowerCase().includes(query.value));
      }
      return props.searchOptions;
    default:
      return [];
  }
});

const remainingCheckedOptions = computed(() => checkedOptions.value.filter((x) => !props.searchOptions.find((y) => x.id == y.id) || !filteredSearchOptions.value.find((y) => x.id == y.id)));

const canCheckAll = computed(() => {
  return (
    (props.searchOptions.length > 0 && props.searchOptions.filter((x) => !checkedOptions.value.find((y) => x.id == y.id)).length > 0) ||
    (props.searchOptions.length == 0 && checkedOptions.value.length == 0)
  );
});

function isChecked(item) {
  return checkedOptions.value.find((x) => x.id == item.id);
}

function toggleSelect(item) {
  if (checkedOptions.value.find((x) => x.id == item.id)) {
    removeFilterOption(item);
  } else {
    checkedOptions.value.push(item);
  }
}

function removeFilterOption(item) {
  checkedOptions.value = checkedOptions.value.filter((x) => x.id != item.id);
}

function selectAll() {
  //console.log("FilterItem, selectAll: ", props.searchOptions);
  if (props.searchOptions.length > 0) {
    checkedOptions.value.push(...props.searchOptions);
  } else {
    emits("filter-search", props.filterIndex, "*");
  }
}

function deselectAll() {
  if (props.searchOptions.length > 0) {
    checkedOptions.value = checkedOptions.value.filter((x) => !props.searchOptions.find((y) => x.id == y.id));
  } else {
    checkedOptions.value = [];
  }
}

function save() {
  if (props.type == "range") {
    active.value = true;
    collapse();
    emits("filter-activated", { index: props.filterIndex, min: min.value, max: max.value });
  } else {
    if (checkedOptions.value.length > 0) {
      active.value = true;
      collapse();
      emits("filter-activated", { index: props.filterIndex, values: checkedOptions.value.map((x) => x.id) });
    } else {
      reset();
    }
  }
}

function reset() {
  //console.log("FilterItem, reset");
  active.value = false;
  clearSearch();
  checkedOptions.value = [];
  resetMinMaxToDefault();

  emits("filter-deactivated", props.filterIndex);
  collapse();
}

function resetMinMaxToDefault() {
  if (props.type == "range") {
    if (props.searchOptions.length > 0) {
      min.value = Math.min(...props.searchOptions.map((x) => x.value));
      max.value = Math.max(...props.searchOptions.map((x) => x.value));
    } else {
      min.value = 0;
      max.value = 100;
    }
  }
}

function clearSearch() {
  //console.log("FilterItem, clearSearch");
  query.value = undefined;
}

const onInput = debounce((txt) => {
  //  console.log("SearchFilterItem, watch debounce: ", txt);
  emits("filter-search", props.filterIndex, txt);
}, 250);

watch(
  () => query.value,
  (newValue) => {
    //console.log("SearchFilterItem, watch query: ", newValue);
    onInput(newValue);
  }
);
</script>

<template>
  <div class="filter-item">
    <div class="btn-filter" @click.prevent="toggle" :class="{ active: expanded || active }">
      {{ name }}
      <v-btn class="ml-5" @click.native.stop="reset()" v-if="active" icon x-small> <v-icon color="white"> mdi-close </v-icon></v-btn>
    </div>

    <v-container class="filter-dropdown" v-show="expanded === true">
      <header>
        <h3>Filteren op {{ nameLowerCase }}</h3>
      </header>

      <template v-if="props.type == 'search' || props.type == 'list'">
        <v-row>
          <v-col cols="2">
            <v-btn class="btn-flex" v-if="canCheckAll" @click.prevent="selectAll">
              <v-icon dark title="Alles aanvinken" v-if="searchOptions.length > 0">mdi-checkbox-multiple-marked-outline</v-icon>
              <v-icon title="Ophalen keuzelijst" v-else>mdi-sort-alphabetical-variant</v-icon>
            </v-btn>
            <v-btn class="btn-flex" v-else @click.prevent="deselectAll" title="Selectie wissen"><v-icon>mdi-checkbox-multiple-blank-outline</v-icon></v-btn>
          </v-col>
          <v-col>
            <input type="search" class="filter-search" v-model="query" :placeholder="'Filter op ' + nameLowerCase" />
          </v-col>
          <v-col cols="2">
            <v-btn slot="append" class="btn-flex" @click.prevent="clearSearch"><v-icon title="Wis zoekveld">mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row v-if="props.searchStatus === FilterStatus.TooMany">
          <v-col> Er zijn teveel resultaten<br />Maak de filter specifieker </v-col>
        </v-row>
        <v-row v-else-if="props.searchStatus === FilterStatus.Empty">
          <v-col> Er zijn geen resultaten<br />Maak de filter minder specifiek </v-col>
        </v-row>

        <div class="list-overview" v-if="filteredSearchOptions.length > 0">
          <perfect-scrollbar>
            <div class="form-check" v-for="option in filteredSearchOptions" :key="`searchOption-${option.text}`">
              <input class="form-check-input" type="checkbox" :checked="isChecked(option)" @change="toggleSelect(option)" :value="option.id" :id="`checkedOption-${nameLowerCase}-${option.id}`" />
              <span class="checkmark"></span>

              <label class="form-check-label" :for="`checkedOption-${nameLowerCase}-${option.id}`">
                {{ option.text }}
              </label>
            </div>
          </perfect-scrollbar>
        </div>

        <div class="list-overview" v-if="remainingCheckedOptions.length > 0">
          <hr class="white mb-5" v-if="searchOptions.length > 0" />

          <perfect-scrollbar>
            <div class="form-check" v-for="option in remainingCheckedOptions" :key="`checkedOption-${nameLowerCase}-${option.id}`">
              <input
                class="form-check-input"
                type="checkbox"
                :checked="isChecked(option)"
                @change="removeFilterOption(option)"
                :value="option.id"
                :id="`checkedOption-${nameLowerCase}-${option.id}`"
              />

              <span class="checkmark"></span>

              <label class="form-check-label" :for="`checkedOption-${nameLowerCase}-${option.id}`">
                {{ option.text }}
              </label>
            </div>
          </perfect-scrollbar>
        </div>
      </template>

      <template v-if="props.type == 'range'">
        <v-row>
          <v-col cols="6">
            <label :for="`filter-${name}-minimum`">minimum</label>
            <input type="number" min="0" class="filter-search" :for="`filter-${name}-minimum`" v-model.number="min" />
          </v-col>
          <v-col cols="6">
            <label :for="`filter-${name}-maximum`">maximum</label>
            <input type="number" min="0" class="filter-search" :for="`filter-${name}-maximum`" v-model.number="max" />
          </v-col>
        </v-row>
      </template>

      <footer>
        <v-btn light @click.prevent="save()" title="Filter opslaan">Opslaan</v-btn>
        <v-btn light @click.prevent="reset()" title="Filter wissen"><v-icon>mdi-delete</v-icon></v-btn>
      </footer>
    </v-container>
  </div>
</template>
