import { Permission } from "@/stores/user";

export const routesIam = [
  {
    path: "/iam",
    name: "IAM",
    component: () => import("@/views/iam/BaseOverview.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
  },
  {
    path: "/iam/overview",
    name: "IAM-BaseOverview",
    component: () => import("@/views/iam/BaseOverview.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
  },
  {
    path: "/iam/users",
    name: "IAM-UsersBase",
    component: () => import("@/views/iam/UsersBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "overview",
        name: "IAM-UsersOverview",
        component: () => import("@/views/iam/UsersOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "logins",
        name: "IAM-UsersLogins",
        component: () => import("@/views/iam/UsersLogins.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },

  {
    path: "/iam/user",
    name: "IAM-UserBase",
    text: "hoi",
    component: () => import("@/views/iam/UserBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-UserAdd",
        component: () => import("@/views/iam/UserDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/details",
        name: "IAM-UserDetails",
        component: () => import("@/views/iam/UserDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/usergroups",
        name: "IAM-UserUserGroups",
        component: () => import("@/views/iam/UserUserGroups.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/resources",
        name: "IAM-UserResources",
        component: () => import("@/views/iam/AccessResources.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/projects",
        name: "IAM-UserProjects",
        component: () => import("@/views/iam/AccessProjects.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/roles",
        name: "IAM-UserRoles",
        component: () => import("@/views/iam/AccessRoles.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":userId/logins",
        name: "IAM-UserLogins",
        component: () => import("@/views/iam/UserLogins.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/usergroups",
    name: "IAM-UserGroups",
    component: () => import("@/views/iam/UserGroups.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "overview",
        name: "IAM-UserGroupsOverview",
        component: () => import("@/views/iam/UserGroupsOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/usergroup",
    name: "IAM-UserGroup",
    component: () => import("@/views/iam/UserGroup.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-UserGroupAdd",
        component: () => import("@/views/iam/UserGroupDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-UserGroupsOverview",
        },
      },
      {
        path: ":groupId/details",
        name: "IAM-UserGroupDetails",
        component: () => import("@/views/iam/UserGroupDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":groupId/users",
        name: "IAM-UserGroupUsers",
        component: () => import("@/views/iam/UserGroupUsers.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":groupId/resources",
        name: "IAM-UserGroupResources",
        component: () => import("@/views/iam/AccessResources.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":groupId/projects",
        name: "IAM-UserGroupProjects",
        component: () => import("@/views/iam/AccessProjects.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":groupId/roles",
        name: "IAM-UserGroupRoles",
        component: () => import("@/views/iam/AccessRoles.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/resources",
    name: "IAM-ResourcesBase",
    component: () => import("@/views/iam/ResourcesBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "overview",
        name: "IAM-ResourcesOverview",
        component: () => import("@/views/iam/ResourcesOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/resource",
    name: "IAM-ResourceBase",
    component: () => import("@/views/iam/ResourceBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-ResourceAdd",
        component: () => import("@/views/iam/ResourceDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-ResourcesOverview",
        },
      },
      {
        path: ":resourceId/details",
        name: "IAM-ResourceDetails",
        component: () => import("@/views/iam/ResourceDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":resourceId/categories",
        name: "IAM-ResourceCategoriesOverview",
        component: () => import("@/views/iam/ResourceCategoriesOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":resourceId/users",
        name: "IAM-ResourceUsers",
        component: () => import("@/views/iam/AccessUsers.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":resourceId/usergroups",
        name: "IAM-ResourceUserGroups",
        component: () => import("@/views/iam/AccessUserGroups.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":resourceId/projects",
        name: "IAM-ResourceProjects",
        component: () => import("@/views/iam/AccessProjects.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":resourceId/roles",
        name: "IAM-ResourceRoles",
        component: () => import("@/views/iam/AccessRoles.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/projects",
    name: "IAM-ProjectsBase",
    component: () => import("@/views/iam/ProjectsBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "overview",
        name: "IAM-ProjectsOverview",
        component: () => import("@/views/iam/ProjectsOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/project",
    name: "IAM-ProjectBase",
    component: () => import("@/views/iam/ProjectBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-ProjectAdd",
        component: () => import("@/views/iam/ProjectDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-ProjectsOverview",
        },
      },
      {
        path: ":projectId/details",
        name: "IAM-ProjectDetails",
        component: () => import("@/views/iam/ProjectDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/links",
        name: "IAM-ProjectLinksOverview",
        component: () => import("@/views/iam/ProjectLinksOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/link/add",
        name: "IAM-ProjectLinkAdd",
        component: () => import("@/views/iam/ProjectLinkDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-ProjectLinksOverview",
        },
      },
      {
        path: ":projectId/link/:projectLinkId/details",
        name: "IAM-ProjectLinkDetails",
        component: () => import("@/views/iam/ProjectLinkDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/users",
        name: "IAM-ProjectUsers",
        component: () => import("@/views/iam/AccessUsers.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/usergroups",
        name: "IAM-ProjectUserGroups",
        component: () => import("@/views/iam/AccessUserGroups.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/resources",
        name: "IAM-ProjectResources",
        component: () => import("@/views/iam/AccessResources.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":projectId/roles",
        name: "IAM-ProjectRoles",
        component: () => import("@/views/iam/AccessRoles.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/roles",
    name: "IAM-RolesBase",
    component: () => import("@/views/iam/RolesBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "overview",
        name: "IAM-RolesOverview",
        component: () => import("@/views/iam/RolesOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "permissions/overview",
        name: "IAM-PermissionsOverview",
        component: () => import("@/views/iam/PermissionsOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: "categories",
        name: "IAM-PermissionCategoriesOverview",
        component: () => import("@/views/iam/PermissionCategoriesOverview.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/role",
    name: "IAM-RoleBase",
    component: () => import("@/views/iam/RoleBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-RoleAdd",
        component: () => import("@/views/iam/RoleDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-RolesOverview",
        },
      },
      {
        path: ":roleId/details",
        name: "IAM-RoleDetails",
        component: () => import("@/views/iam/RoleDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":roleId/permissions",
        name: "IAM-RolePermissions",
        component: () => import("@/views/iam/RolePermissions.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":roleId/users",
        name: "IAM-RoleUsers",
        component: () => import("@/views/iam/AccessUsers.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":roleId/usergroups",
        name: "IAM-RoleUserGroups",
        component: () => import("@/views/iam/AccessUserGroups.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":roleId/projects",
        name: "IAM-RoleProjects",
        component: () => import("@/views/iam/AccessProjects.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":roleId/resources",
        name: "IAM-RoleResources",
        component: () => import("@/views/iam/AccessResources.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/permission",
    name: "IAM-PermissionBase",
    component: () => import("@/views/iam/PermissionBase.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-PermissionAdd",
        component: () => import("@/views/iam/PermissionDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-PermissionsOverview",
        },
      },
      {
        path: ":permissionKey/details",
        name: "IAM-PermissionDetails",
        component: () => import("@/views/iam/PermissionDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":permissionKey/roles",
        name: "IAM-PermissionRoles",
        component: () => import("@/views/iam/PermissionRoles.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":permissionKey/categories",
        name: "IAM-PermissionCategories",
        component: () => import("@/views/iam/PermissionCategories.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
  {
    path: "/iam/permission-category",
    name: "IAM-PermissionCategory",
    component: () => import("@/views/iam/PermissionCategory.vue"),
    props: true,
    meta: {
      authorize: [Permission.Admin],
    },
    children: [
      {
        path: "add",
        name: "IAM-PermissionCategoryAdd",
        component: () => import("@/views/iam/PermissionCategoryDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
          withoutAddmodus: "IAM-PermissionCategoriesOverview",
        },
      },
      {
        path: ":permissionCategoryId/details",
        name: "IAM-PermissionCategoryDetails",
        component: () => import("@/views/iam/PermissionCategoryDetails.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":permissionCategoryId/permissions",
        name: "IAM-PermissionCategoryPermissions",
        component: () => import("@/views/iam/PermissionCategoryPermissions.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
      {
        path: ":permissionCategoryId/resources",
        name: "IAM-PermissionCategoryResources",
        component: () => import("@/views/iam/PermissionCategoryResources.vue"),
        props: true,
        meta: {
          authorize: [Permission.Admin],
        },
      },
    ],
  },
];
