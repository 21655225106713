<script lang="ts" setup>
const emits = defineEmits(["add-new-detail", "on-cancel-add", "on-cancel", "save-new-detail", "save-detail", "edit-tl", "edit"]);

const props = defineProps({
  addMode: {
    type: Boolean,
    default: false,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
  allowSave: {
    type: Boolean,
    default: true,
  },
});

async function onCancel() {
  if (props.addMode) {
    emits("on-cancel-add");
  } else {
    emits("on-cancel");
  }
}

async function onSave() {
  if (props.addMode) {
    emits("save-new-detail");
  } else {
    emits("save-detail");
  }
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" class="form-group" v-show="!props.editMode">
        <v-btn class="primary" v-on:click.stop v-on:click="emits('edit-tl')">
          <v-icon dark left>mdi-pencil-circle</v-icon>
          Bewerk geldigheid
        </v-btn>
      </v-col>
      <v-col cols="auto" class="form-group" v-show="!props.editMode">
        <v-btn class="primary" v-on:click.stop v-on:click="emits('edit')">
          <v-icon dark left>mdi-pencil-circle</v-icon>
          Bewerk data
        </v-btn>
      </v-col>
      <v-col cols="auto" class="form-group" v-show="!props.editMode">
        <v-btn class="primary" v-on:click.stop v-on:click="emits('add-new-detail')">
          <v-icon dark left>mdi-plus</v-icon>
          Versie toevoegen
        </v-btn>
      </v-col>
      <v-col cols="auto" class="form-group" v-show="props.editMode">
        <v-btn class="primary" v-on:click="onSave()" :disabled="!props.allowSave">
          <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
          Opslaan
        </v-btn>
      </v-col>
      <v-col cols="auto" class="form-group" v-show="props.editMode">
        <v-btn v-on:click="onCancel()">
          <v-icon dark left>mdi-cancel</v-icon>
          Annuleren
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>
