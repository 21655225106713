<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import "ol/ol.css";
import { OSM } from "ol/source";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Attribution, defaults as defaultControls } from "ol/control";
import { Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";
import Icon from "ol/style/Icon";
import markerImg from "@/assets/img/marker.png";
import GeoJSON from "ol/format/GeoJSON";

const props = defineProps({
  zoomLevel: { type: Number, default: 15 },
  center: {
    type: Object,
    default: () => ({
      lon: 5.5556715,
      lat: 52.0391309,
      name: "Nederland",
    }),
  },
  location: Object,
});

const olMap = ref(null);
const markerLayer = ref(null);
const maproot = ref(null);

onMounted(() => {
  //console.log("mount map");
  markerLayer.value = new VectorLayer({
    source: new VectorSource({
      features: [],
    }),
    style: new Style({
      image: new Icon({
        anchorOrigin: "bottom-left",
        anchor: [13, 0.5],
        anchorXUnits: "pixels",
        scale: 0.75,
        src: markerImg,
      }),
    }),
  });

  olMap.value = new Map({
    // the map will be created using the 'map-root' ref
    target: maproot.value,
    layers: [
      // adding a background tiled layer
      new TileLayer({
        source: new OSM(), // tiles are served by OpenStreetMap
      }),
      markerLayer.value,
    ],

    controls: defaultControls({ attribution: false }).extend([
      new Attribution({
        collapsible: true,
        collapsed: true,
      }),
    ]),

    view: new View({
      projection: "EPSG:3857",
      center: fromLonLat([props.center.lon, props.center.lat]),
      zoom: props.zoomLevel,
      constrainResolution: true,
    }),
  });

  updateSource(undefined);
});

function updateSource(geoLoc) {
  const view = olMap.value.getView();
  const source = markerLayer.value.getSource();
  //console.log("updateSource view ", view);

  source.clear();
  if (geoLoc) {
    const features = new GeoJSON({
      featureProjection: "EPSG:3857",
    }).readFeatures(geoLoc);
    source.addFeatures(features);
    //view.fit(source.getExtent());
    view.setCenter(fromLonLat([geoLoc.geometry.coordinates[0], geoLoc.geometry.coordinates[1]]));
  }
}

watch(
  () => props.location,
  (value) => {
    console.log("LocationMap watch ", value);
    let geo = undefined;
    if (Math.trunc(value.lon) != 0 && Math.trunc(value.lat) != 0) {
      geo = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [value.lon, value.lat],
        },
        properties: {
          name: value.name,
        },
      };
    }

    updateSource(geo);
  }
);
</script>

<template>
  <div ref="maproot" class="location-map"></div>
</template>
