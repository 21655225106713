import axios from "axios";

// api ingang
let apiClient;

export default {
  install(Vue, options) {
    //console.log("httpClientUnauthorized created", options);
    apiClient = axios.create({
      baseURL: `${options.portalUrl}api/`,
      timeout: 20000,
      // timeout: 5000,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
        // anything you want to add to the headers
      }
    });
  }
};

export { apiClient };
