<script lang="ts" setup>
import { ref, watch } from "vue";

const emits = defineEmits(["changedPaging"]);

const props = defineProps({
  pageNumber: {
    type: Number,
  },
  pageSize: {
    type: Number,
  },
  pageCount: {
    type: Number,
    default: 0,
  },
});

const pageSizes = [
  { key: "5", value: 5 },
  { key: "10", value: 10 },
  { key: "25", value: 25 },
  { key: "50", value: 50 },
  { key: "Alles", value: -1 },
];

const selectedPageNumber = ref(1);
const selectedPageSize = ref(10);
const selectedPageCount = ref(1);

watch(
  () => props.pageCount,
  (newValue) => {
    // console.log("GridPagination watch pageCount ", newValue);
    selectedPageCount.value = newValue;
  },
  { immediate: true }
);
watch(
  () => props.pageNumber,
  (newValue) => {
    // console.log("GridPagination watch pageNumber ", newValue);
    selectedPageNumber.value = newValue;
  },
  { immediate: true }
);
watch(
  () => props.pageSize,
  (newValue) => {
    // console.log("GridPagination watch pageSize ", newValue);
    selectedPageNumber.value = 1;
    selectedPageSize.value = newValue;
  },
  { immediate: true }
);

function onPageNumberChange(newValue) {
  // console.log("GridPagination onPageNumberChange", newValue);
  if (selectedPageNumber.value !== newValue) {
    selectedPageNumber.value = newValue;
    emits("changedPaging", { pageNumber: newValue, pageSize: selectedPageSize.value });
  }
}

function onPageSizeChange(newValue) {
  // console.log("GridPagination onPageSizeChange", newValue);

  //reset van v-pagination anders gaat callback update om een of andere reden niet goed, (page=2, size van 2 naar 4, blijven er maar twee zichtbaar??)
  selectedPageNumber.value = null;
  selectedPageCount.value = null;

  emits("changedPaging", { pageNumber: 1, pageSize: newValue });
}
</script>

<template>
  <v-layout>
    <v-row align="center" justify="center">
      <v-col cols="auto">
        <v-pagination :value="selectedPageNumber" @input="onPageNumberChange" :length="selectedPageCount" :total-visible="7"> </v-pagination>
      </v-col>
      <v-col cols="auto"> Regels per pagina </v-col>
      <v-col cols="1">
        <v-select :value="selectedPageSize" @input="onPageSizeChange" :items="pageSizes" item-text="key" item-value="value"> </v-select>
      </v-col>
    </v-row>
  </v-layout>
</template>
