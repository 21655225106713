import { ref } from "vue";
import { defineStore } from "pinia";
import { severityCode } from "@/services/api/httpClient";

//eigenlijk enum
const FilterStatus = {
  None: 0,
  Success: 1,
  Error: 2,
  Empty: 3,
  TooMany: 4,
};

const FilterDataName = {
  Party: "PARTY",
  Location: "LOCATION",
  Measures: "MEASURES",
  Meters: "METERS",
  User: "USER",
  UserLoginLog: "USERLOGINLOG",
  PowerBi: "POWERBI",
};

const useFilterDataStore = defineStore("filterdata", () => {
  const filterItems = ref([]);

  function setFilterItem(filterName, itemName, type, value) {
    // const k = `${filterName}.${itemName}.${type}`;
    //console.log("filterDataStore, setFilterItem ", filterName, itemName, type, value);
    filterItems.value[`${filterName}.${itemName.toLowerCase()}.${type}`] = value;
  }

  function getFilterItem(filterName, itemName, type) {
    return filterItems.value[`${filterName}.${itemName.toLowerCase()}.${type}`];
  }

  function getFilterItemData(filterName, itemName) {
    //console.log("filterDataStore, getFilterItemData ", filterName, itemName);
    const active = getFilterItem(filterName, itemName, "active");
    if (active) {
      const savedCheckedOptions = getFilterItem(filterName, itemName, "checked");
      let data = {};
      if (savedCheckedOptions) {
        data.values = savedCheckedOptions.map((x) => x.id);
      }
      const savedMinmax = getFilterItem(filterName, itemName, "minmax");
      if (savedMinmax) {
        data.min = savedMinmax.min;
        data.max = savedMinmax.max;
      }
      return data;
    }
    return undefined;
  }

  return { setFilterItem, getFilterItem, getFilterItemData };
});

function mapFilterResult(result) {
  return [...result.map((item) => ({ id: item.id && item.id.length > 0 ? item.id : item.text, text: item.text, count: item.count }))];
}

function mapFilterResponseStatus(severity, code) {
  let status = FilterStatus.None;
  switch (severity) {
    case severityCode.none:
    case severityCode.hint:
    case severityCode.warning:
      switch (code) {
        case 204:
          status = FilterStatus.Empty;
          break;
        case 290:
          status = FilterStatus.TooMany;
          break;
        default:
          status = FilterStatus.Success;
          break;
      }
      break;
    case severityCode.error:
      status = FilterStatus.Error;
      break;
  }
  return status;
}

export { useFilterDataStore, FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus };
