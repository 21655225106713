<script lang="ts" setup>
import { ref } from "vue";

const toggled = ref(false);

function toggle() {
  toggled.value = !toggled.value;
}
</script>

<template>
  <div class="information-popup" :class="{ active: toggled }">
    <span class="icon-information-icon" @click="toggle"></span>

    <div class="information-dropdown" v-show="toggled === true">
      <div class="information-dropdown-header">
        <slot name="header"></slot>
        <a href="#" @click.prevent="toggle"></a>
      </div>

      <div class="information-dropdown-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
