const padLeft = function zeroPad(input, length) {
  if (input) {
    return (Array(length + 1).join("0") + input).slice(-length);
  }
};

const emptyGuid = "00000000-0000-0000-0000-000000000000";

function isEmptyGuid(value) {
  return (
    value == null || // NULL value
    value == undefined || // undefined
    value == "undefined" || // undefined
    value === emptyGuid
  ); // Guid empty
}

export { padLeft, emptyGuid, isEmptyGuid };
