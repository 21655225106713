import { apiClient, getRequest, severityCode } from "./httpClient";

const END_POINT = "party";

const getPartyDetails = async (partyReference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${partyReference}/details`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyDetailByReference = async (partyReference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${partyReference}/detail`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyOverview = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  let response = await apiClient
    .post(`${END_POINT}/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyOverviewFilterOptions = async (fieldName, text) => {
  return await getRequest(`${END_POINT}/autocomplete/${fieldName}`, { text: text });
};

const getParties = async () => {
  return getRequest(`${END_POINT}/list`);
};

const getPartyTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/partyTypes`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getSourceTypes = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/sourceTypes`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchPartyDetails = async (partyDetailId, data) => {
  let errorResponse = undefined;
  let response = await apiClient.patch(`${END_POINT}/${partyDetailId}`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // de catch vangt geen 200-range meldingen af
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
      default:
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postPartyDetails = async (data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT}`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getParty = async (partyId) => {
  let errorResponse = null;
  let response = await apiClient.get(`${END_POINT}/details`, { params: { partyId: partyId } }).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    response = "";
  }
  return { data: response.data, error: errorResponse };
};

const getPartyLocationOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/locationsoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyLocationOverviewFilterOptions = async (reference, fieldName, text) => {
  return await getRequest(`${END_POINT}/${reference}/locationsoverview/autocomplete/${fieldName}`, { text: text });
};

const getPartyAddressesOverview = async (partyReference, locationReference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(
      `${END_POINT}/${partyReference}/location/${locationReference}/addressesoverview`,
      { filterData: filterData },
      { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } }
    )
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getPartyReports = async (partyReference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  const response = await apiClient.post(
    `${END_POINT}/${partyReference}/reports`,
    { filterData: filterData },
    { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } }
  );
  return response;
};

const getPartyReportsList = async (partyReference) => {
  let errorResponse = null;
  const response = await apiClient.get(`${END_POINT}/${partyReference}/reportslist`).catch((error) => {
    errorResponse = error.toString();
  });
  if (errorResponse) {
    return { data: null, error: errorResponse };
  }
  return { data: response.data, error: errorResponse };
};

const getPartyReportsFilterOptions = async (partyReference, fieldName, text) => {
  return await getRequest(`${END_POINT}/${partyReference}/reports/autocomplete/${fieldName}`, { partyReference: partyReference, text: text });
};

const deleteLogo = async (partyReference) => {
  await apiClient.delete(`asset/logo/${partyReference}`);
};

export {
  getPartyOverview,
  getPartyOverviewFilterOptions,
  getPartyDetails,
  getPartyDetailByReference,
  getParties,
  getPartyTypes,
  getSourceTypes,
  patchPartyDetails,
  postPartyDetails,
  getParty,
  getPartyLocationOverview,
  getPartyLocationOverviewFilterOptions,
  getPartyAddressesOverview,
  getPartyReports,
  getPartyReportsList,
  getPartyReportsFilterOptions,
  deleteLogo,
  severityCode,
};
