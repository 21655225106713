import { required, email, max, min, min_value, max_value, numeric, between, double } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
const { formatDateUtcD, dateBetween, toUtcFormat } = useDateTimeFormatter();
import {emptyGuid} from '@/composables/miscellaneous.js'

extend("date_after", {
  validate(value, { validFrom }) {
    return value > validFrom;
  },
  params: ["validFrom", "target"],
  message: (fieldName, args) => {
    return `${fieldName} moet groter zijn dan  ${toUtcFormat(args.validFrom)}`;
  }});

extend("required", {
  ...required,
  message: "{_field_}  is verplicht",
});

extend("max", {
  ...max,
  message: "{_field_} mag niet groter zijn dan {length} karakters",
});

extend("min", {
  ...min,
  message: "{_field_} moet minimaal {length} karakters zijn",
});

extend("email", {
  ...email,
  message: "{_field_} moet een geldig e-mailadres zijn",
});

extend("numeric", {
  ...numeric,
  message: "{_field_} mag alleen cijfers bevatten",
});

extend("between", {
  ...between,
  message: "{_field_} mag alleen waarden bevatten tussen {min} en {max}",
});

extend("min_value", {
  ...min_value,
  message: "{_field_} moet minimaal {min} zijn",
});

extend("max_value", {
  ...max_value,
  message: "{_field_} mag maximaal {max} zijn",
});

const defaultMinMax = { min: "1200-01-01", max: "2050-12-31" };

extend("date_between", {
  params: ["min", "max"],
  validate: (value, { min = defaultMinMax.min, max = defaultMinMax.max } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    const validBetween = dateBetween(value, min, max);

    return {
      valid: validBetween,
    };
  },
  message: (field, args) => {
    return `${field} moet liggen tussen ${formatDateUtcD(args.min ? args.min : defaultMinMax.min)} en ${formatDateUtcD(args.max ? args.max : defaultMinMax.max)}`;
  },
});

extend("double", {
  ...double,
  message: (field) => `${field} moet een geldig decimaal zijn`,
});

extend("decimal", {
  params: ["decimals", "separator"],
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    //console.log("vee-validate, decimal :", decimals);

    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }

    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: (field, args) => `${field} mag maximaal ${args.decimals} decimalen hebben`,
});

extend("postalcode", {
  validate: (value) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }

    const regex = new RegExp(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i);
    //   console.log("vee-validate, postalcode :", regex);

    return {
      valid: regex.test(value),
    };
  },
  message: () => `Geen geldige postcode`,
});

extend("unequal_Onbekend", {
  validate: (value) => {
    console.log("vee-validate, unequal_Onbekend :", value);
    return {
      valid: value !== "Onbekend",
    };
  },
  message: () => `Onbekend is geen geldige waarde`,
});

extend("test", {
  params: ["min", "max"],
  validate: (value, { min = 11, max = 12 } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    console.log("vee-validate, test :", value, min, max);
    return false;
  },
  message: (field, args) => `The ${field} value is not truthy, min: ${args.min} , max: ${args.max}`,
});

extend('guid', {
  ...required,
  validate(value) {
    const correctSyntax = value.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');

    return value && correctSyntax && value !== emptyGuid;
  },
  message: "{_field_}  is geen geldige Guid",
});


// "alpha": "{_field_} mag alleen letters bevatten",
// "alpha_dash": "{_field_} mag alleen letters, nummers, en streepjes bevatten",
// "alpha_num": "{_field_} mag alleen letters en nummers bevatten",
// "alpha_spaces": "{_field_} mag alleen letters en spaties bevatten",
// "between": "{_field_} moet tussen {min} en {max} liggen",
// "confirmed": "{_field_} bevestiging komt niet overeen",
// "digits": "{_field_} moet een nummer zijn en exact {length} tekens bevatten",
// "dimensions": "{_field_} moet {width} pixels breed zijn en {height} pixels hoog",
// "email": "{_field_} moet een geldig e-mailadres zijn",
// "excluded": "{_field_} is ongeldig",
// "ext": "{_field_} moet een geldig bestand zijn",
// "image": "{_field_} moet een afbeelding zijn",
// "integer": "{_field_} moet een nummer zijn",
// "length": "{_field_} moet {length} karakters zijn",
// "max": "{_field_} mag niet groter zijn dan {length} karakters",
// "max_value": "{_field_} mag maximaal {max} zijn",
// "mimes": "{_field_} moet een geldig bestandstype hebben",
// "min": "{_field_} moet minimaal {length} karakters zijn",
// "min_value": "{_field_} moet minimaal {min} zijn",
// "numeric": "{_field_} mag alleen nummers bevatten",
// "oneOf": "{_field_} moet een geldige waarde zijn",
// "regex": "{_field_} formaat is ongeldig",
// "required": "{_field_} is verplicht",
// "required_if": "{_field_} is verplicht",
// "size": "{_field_} mag niet groter zijn dan {size}KB",
// "double": "{_field_} moet een geldige decimaal zijn"
