import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { setCurrentUser } from "@/services/api/user.api";
// import { setCurrentProject } from "@/services/api/user.api";

const Permission = {
  Admin: "Admin",
  User: "User",
  UpdateEnergyUsage: "UpdateEnergyUsage",
  Welcome: "Welcome",
  ImportExcel: "ImportExcel",
  ImportVabi: "ImportVabi",
};

const useUserStore = defineStore("user", () => {
  const userValid = ref(false);

  const userProfile = ref({
    userId: "",
    userName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fullName: "",
    emailAddress: "",
    isUser: false,
  });

  const userProject = ref({
    projectId: "",
    projectName: "",
    projectLogoName: "",
    partyReference: 0,
    selectableProjects: [],
  });

  const userAccess = ref({
    permissions: [],
    isActive: false,
    lastLogin: null,
  });

  const isUser = computed(() => userProfile.value.isUser);
  const isUserValid = computed(() => userValid.value);

  const getUserFullname = computed(() => userProfile.value.fullName);

  const friendlyName = computed(() => {
    let name = "";
    if (userProfile.value.firstName != null) {
      name = userProfile.value.firstName;
    } else {
      if (userProfile.value.fullName != null) {
        name = userProfile.value.fullName;
      }
    }
    return name;
  });

  const isAdministrator = computed(() => matchingPermission([Permission.Admin]));
  const hasUpdateEnergyUsage = computed(() => matchingPermission([Permission.UpdateEnergyUsage]));
  const hasPermissionWelcome = computed(() => matchingPermission([Permission.Welcome]));
  const hasPermissionImportExcel = computed(() => matchingPermission([Permission.ImportExcel]));
  const hasPermissionImportVabi = computed(() => matchingPermission([Permission.ImportVabi]));

  function matchingPermission(authorize) {
    return userAccess.value.permissions ? userAccess.value.permissions.some((r) => authorize.indexOf(r) >= 0) : false;
  }

  async function isAuthorized(authorize) {
    console.log("userStore, isAuthorized, checkStoreState ");
    await checkStoreState();
    const matchingPermissions = matchingPermission(authorize);
    console.log("userStore, isAuthorized, ", matchingPermissions);
    return matchingPermissions;
  }

  async function checkStoreState() {
    //console.log("store, checkStoreState ", context.state.userProfile, context.state.userProfile.userId);
    if (!userProfile.value.userId || userProfile.value.userId == "") {
      await setUserAccessData();
      // console.log("store, checkStoreState ", userData, currentUser.profile);
    }
  }

  async function setUserAccessData() {
    console.log("store, setUserAccessData ");
    const userData = await setCurrentUser();
    console.log("store, setUserAccessData ", userData);
    // De opgehaalde UserAccess data wordt in store bewaard.
    userValid.value = true;
    userProfile.value.userId = userData.data.profile.userId;
    userProfile.value.firstName = userData.data.profile.firstName;
    userProfile.value.middleName = userData.data.profile.middleName;
    userProfile.value.lastName = userData.data.profile.lastName;
    userProfile.value.fullName = userData.data.profile.fullName;
    userProfile.value.emailAddress = userData.data.profile.email;

    if (userData.data.access) {
      userAccess.value.permissions = [...userData.data.access.permissions];
    }
    if (userData.data.projects) {
      userProject.value.projectId = userData.data.projects.projectId;
      userProject.value.projectName = userData.data.projects.projectName;
      userProject.value.projectLogoName = userData.data.projects.projectLogoName;

      userProject.value.partyReference = userData.data.projects.partyRef;
      userProject.value.selectableProjects = [...userData.data.projects.selectableProjects];
    }
  }

  return {
    isUser,
    isUserValid,
    isAdministrator,
    getUserFullname,
    friendlyName,

    hasUpdateEnergyUsage,
    hasPermissionWelcome,
    hasPermissionImportExcel,
    hasPermissionImportVabi,
    matchingPermission,

    isAuthorized,
    checkStoreState,
    setUserAccessData,
  };
});

export { useUserStore, Permission };
