<script lang="ts" setup>
import { computed } from "vue";
import _ from "lodash";

const props = defineProps({
  items: undefined,
});

const breadcrumbs = computed(() => {
  console.log("InnBreadcrumbs, breadcrumbs ", props.items);
  const list = [
    {
      icon: "mdi-home",
      exact: true,
      text: "<v-icon>mdi-account</v-icon>",
      to: { name: "Dashboard" },
    },
  ];
  if (props.items) {
    props.items.forEach((element) => {
      console.log("breadcrumbs ", element);
      if (element.default) {
        //als dit tweede element is en het is een IAM...
        if (list.length == 1) {
          if (_.startsWith(element.default, "IAM")) {
            list.push({ exact: true, text: "Identiteits- en toegangsbeheer", to: { name: "IAM-BaseOverview" } });
          }
          if (_.startsWith(element.default, "EU")) {
            list.push({ exact: true, text: "Energiebeheer", to: { name: "EU-Base" } });
          }
        }
        switch (element.default) {
          case "IAM-UsersOverview":
            list.push({
              exact: true,
              text: "Gebruikers",
              to: { name: "IAM-UsersOverview" },
            });
            break;
          case "IAM-UserGroupsOverview":
            list.push({
              exact: true,
              text: "Groepen",
              to: { name: "IAM-UserGroupsOverview" },
            });
            break;
          case "IAM-ResourcesOverview":
            list.push({
              exact: true,
              text: "Resources",
              to: { name: "IAM-ResourcesOverview" },
            });
            break;
          case "IAM-ProjectsOverview":
            list.push({
              exact: true,
              text: "Projecten",
              to: { name: "IAM-ProjectsOverview" },
            });
            break;
          case "IAM-RolesOverview":
            list.push({
              exact: true,
              text: "Rollen",
              to: { name: "IAM-RolesOverview" },
            });
            break;
          case "IAM-PermissionsOverview":
            list.push({
              exact: true,
              text: "Rechten",
              to: { name: "IAM-PermissionsOverview" },
            });
            break;
          case "IAM-PermissionCategoriesOverview":
            list.push({
              exact: true,
              text: "Rechten-Resources categorie",
              to: { name: "IAM-PermissionCategoriesOverview" },
            });
            break;

          case "EU-Registers":
            list.push({
              exact: true,
              text: "Meters",
              to: { name: "EU-Registers" },
            });
            break;
        }
      } else {
        list.push({
          exact: true,
          text: element.text,
          to: element.to,
        });
      }
    });
  }
  console.log("breadcrumbs list ", list);

  return list;
});
</script>

<template>
  <v-breadcrumbs class="pb-0" :items="breadcrumbs" divider=">">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.to">
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        <template v-else>{{ item.text }}</template>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
