<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();

function login() {
  authStore.login();
}
</script>

<template>
  <v-layout flex-column class="login">
    <header>
      <h1 class="large">Welkom bij de pandenlijst</h1>
    </header>

    <footer>
      <button class="btn-primary" @click="login">Inloggen</button>
    </footer>
  </v-layout>
</template>
