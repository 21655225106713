<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getDefinitionOverview, getDefinitionFilterOptions, getKinds } from "@/services/api/measure.api";
import useGrid27 from "@/composables/grid27.js";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import GridPagination from "@/components/general/GridPagination.vue";

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  explicitPageSize: {
    type: Number,
    default: undefined,
  },
});

const filterData = ref([]);

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },

  { text: "Onderdeel", value: "part" },
  { text: "Code", value: "code" },
  { text: "Naam", value: "name" },
  { text: "Omschrijving", value: "descr" },
];
const headers = computed(() => _headers);

const filters = ref([
  {
    name: "Type",
    fieldName: "kind",
    type: "list",
    options: [],
  },
  {
    name: "Code",
    fieldName: "code",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Naam",
    fieldName: "name",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

watch(
  () => filterData.value,
  () => {
    //console.log("%cMeasureOverviewGrid, filterData", "background: GoldenRod; color: DarkOrchid");
    fetchFirstPage();
  },
  { deep: true }
);

watch(
  () => props.explicitPageSize,
  () => {
    //console.log("MeasureOverviewGrid, watch explicitPageSize", props.explicitPageSize);
    if (props.explicitPageSize) {
      pageSize.value = props.explicitPageSize;
    }
  },
  { immediate: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  //console.log("%cMeasureOverviewGrid, getData", "background: GoldenRod; color: DarkOrchid", pageNumber);
  const pageNumber0 = pageNumber - 1;
  const response = await getDefinitionOverview(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  //console.log("%cMeasureOverviewGrid, getData", "background: GoldenRod; color: DarkOrchid", response);
  return response;
}

async function handleFilterSearch(index, text) {
  //console.log("MeasureOverviewGrid, handleFilterSearch ", index, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "kind":
      {
        const responseKind = await getKinds();
        //console.log("MeasureOverviewGrid, handleFilterSearch kind ", responseKind);
        result = responseKind.data.keyDescList.map((x) => ({
          id: x.key,
          text: x.description,
        }));
      }
      break;
    default:
      if (text && text.length > 0) {
        const response = await getDefinitionFilterOptions(filters.value[index].fieldName, text);
        //console.log("MeasureOverviewGrid, handleFilterSearch other", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function handleFiltersChanged(newFilterData) {
  //console.log("MeasureOverview handleFiltersChanged filterData: ", newFilterData);
  filterData.value = [...newFilterData];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.Measures" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <!-- group-by="functionCode" -->
      <!-- <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ items[0].functionCode }} - {{ items[0].functionName }}
        </td>
      </template> -->

      <template v-slot:[`item.part`]="{ item }">
        <span class="text-no-wrap">
          <template v-if="item.categoryDescription">{{ item.categoryDescription }} ({{ item.categoryMainDescription }})</template>
          <template v-else> {{ item.functionCode }}-{{ item.functionName }} </template>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'MeasureDetails', params: { id: item.id, addModus: false } }" icon small plain>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
