import { apiClient } from "./httpClient";

const END_POINT = "report";

const getOverallSummary = async () => {
  //console.log("report.api, apiClient: ", apiClient);
  const response = await apiClient.get(`${END_POINT}/info`);
  //console.log("response: ", response);
  return response;
};

const getSummaryLocations = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/info/locations`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getSummaryParties = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/info/parties`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getSummaryEcmDefinitions = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/info/ecmdefinitions`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getSummaryRegisters = async () => {
  let errorResponse = undefined;
  const response = await apiClient.get(`${END_POINT}/info/registers`).catch(error => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

export { getOverallSummary, getSummaryLocations, getSummaryParties, getSummaryEcmDefinitions, getSummaryRegisters };
