<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getPartyOverview, getPartyOverviewFilterOptions, getSourceTypes } from "@/services/api/party.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import useGrid27 from "@/composables/grid27.js";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import GridPagination from "@/components/general/GridPagination.vue";

const { formatDateUtc } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 25;

// const props = defineProps({
//   allowMutation: {
//     type: Boolean,
//     default: false,
//   },
// });

const filterData = ref([]);

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Reference", value: "reference" },
  { text: "", value: "typeKey", width: 20 },
  { text: "Naam", value: "name" },
  { text: "Omschrijving", value: "descr" },
  { text: "Bron", value: "sourceType" },
  { text: "Bron referentie", value: "sourceReference" },
  { text: "Begindatum", value: "dateFrom" },
  { text: "Einddatum", value: "dateTill" },
  { text: "Versies", value: "detailCnt", sortable: false, align: "center" },
  {
    text: "Bovenliggende party",
    value: "parName",
    sortable: false,
  },
  { text: "Gewijzigd door", value: "modBy" },
];
const headers = computed(() => _headers);

const filters = ref([
  {
    name: "Naam",
    fieldName: "name",
    type: "search",
    options: [],
  },
  {
    name: "Omschrijving",
    fieldName: "descr",
    type: "search",
    options: [],
  },
  {
    name: "Bron",
    fieldName: "sourceType",
    type: "list",
    options: [],
  },
  {
    name: "Bron referentie",
    fieldName: "sourceReference",
    type: "search",
    options: [],
  },
]);

watch(
  () => filterData.value,
  () => {
    //console.log("PartyOverviewGrid watch filterData : ", filterData.value);
    fetchFirstPage();
  },
  { deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("%cPartyOverviewGrid, getData filter", "background: #e3fbe3; color: black", pageSize, filterData.value);
  const pageNumber0 = pageNumber - 1;
  const response = await getPartyOverview(pageNumber0, pageSize, sortBy, sortDesc, filterData.value);
  //console.log("%cPartyOverviewGrid, getData response", "background: #e3fbe3; color: black", pageSize, response);
  return response;
}

async function handleFilterSearch(index, text) {
  //console.log("PartyOverviewGrid handleFilterSearch: ", filters.value[index].fieldName, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "sourceType":
      {
        const response = await getSourceTypes();
        //    console.log("PartyOverviewGrid, getFilterOptions ", response);
        result = response.data.keyDescList.map((x) => ({
          id: x.key,
          text: x.descr,
        }));
      }
      break;

    default:
      {
        if (text && text.length > 0) {
          //    console.log("PartyOverviewGrid handleFilterSearch: ", filters.value[index].fieldName, text);
          const response = await getPartyOverviewFilterOptions(filters.value[index].fieldName, text);
          console.log("PartyOverviewGrid handleFilterSearch: ", response);
          status = mapFilterResponseStatus(response.severity, response.code);
          if (status === FilterStatus.Success) {
            result = response.data.data;
          } else {
            if (status === FilterStatus.Error) {
              proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
            }
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function handleFiltersChanged(newFilterData) {
  //console.log("PartyOverviewGrid handleFiltersChanged filterData: ", newFilterData);
  filterData.value = [...newFilterData];
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  //console.log("BuildingGrid fetchError", value);
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.Party" :filters="filters" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.dateFrom`]="{ item }">
        {{ formatDateUtc(item.dateFrom) }}
      </template>
      <template v-slot:[`item.dateTill`]="{ item }">
        {{ formatDateUtc(item.dateTill) }}
      </template>

      <template v-slot:[`item.typeKey`]="{ item }">
        <v-icon v-if="item.typeKey == 'EnergyLabels'">mdi-label-outline</v-icon>
        <v-icon v-else-if="item.typeKey == 'Homes'">mdi-home-outline</v-icon>
        <v-icon v-else-if="item.typeKey == 'Buildings'">mdi-office-building-outline</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'Party', params: { partyReference: item.reference.toString(), addModus: false } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
