import { apiClient, deleteRequest } from "./httpClient";
import { getRequest, postRequest, patchRequest, severityCode } from "./httpClient";

const END_POINT = "location";

const getLocation = async (reference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${reference}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationDetails = async (locationReference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/details`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationChildDetails = async (locationReference, id) => {
  return getRequest(`${END_POINT}/${locationReference}/sublocation/${id}`);
};

const patchLocationChilds = async (parentLocationReference, id, childLocationReference, validity) => {
  return patchRequest(`${END_POINT}/${parentLocationReference}/sublocation/${id}`, {
    locationReference: childLocationReference,
    validFrom: validity.validFrom,
    validTill: validity.validTill,
    modifiedDescription: validity.modifiedDescription,
  });
};

const getLocationDetailByReference = async (locationReference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/detail`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationDetail = async (locationDetailId) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationDetailId}/detail`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationOverview = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  let response = await apiClient
    .post(`${END_POINT}/overview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationOverviewFilterOptions = async (fieldName, text) => {
  console.log("getLocationOverviewFilterOptions, fieldname, text: ", fieldName, text);
  return await getRequest(`${END_POINT}/autocomplete/${fieldName}`, { text: text });
};

const getAddressesOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/addressesoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getAddressesOverviewChilds = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/addressesoverview/childs`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationPartyOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/partyoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchLocationDetails = async (locationDetailId, data) => {
  let errorResponse = undefined;
  let response = await apiClient.patch(`${END_POINT}/${locationDetailId}`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // de catch vangt geen 200-range meldingen af
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
      default:
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postLocationWizard = async (data) => {
  console.log("location.api, postLocationWizard, data: ", data);
  return await postRequest(`${END_POINT}/wizard`, { ...data });
};

const postLocationDetails = async (data) => {
  return await postRequest(`${END_POINT}`, { ...data });

  // let errorResponse = undefined;
  // let response = await apiClient.post(`${END_POINT}`, { ...data }).catch(error => {
  //   switch (error.response.status) {
  //     // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
  //     case 409: // conflict
  //       errorResponse = "Er is een conflict op de tijdlijn";
  //       break;
  //     default:
  //       errorResponse = error.toString();
  //   }
  // });
  // if (response) {
  //   switch (response.status) {
  //     // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
  //     case 204: // no-content
  //       errorResponse = "Er zijn geen gegevens geinsert";
  //       break;
  //     default:
  //   }
  // }
  // return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationDiagram = async (locationReference) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/diagram`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationRegisterDetails = async (locationReference, id) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/locationregister/${id}/details`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchLocationRegisterDetails = async (locationReference, detailId, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT}/${locationReference}/locationregister/${detailId}`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    switch (apiError.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = apiError.toString();
    }
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postLocationRegisterDetails = async (locationReference, data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT}/${locationReference}/locationregister`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const postLocationNewRegisterDetails = async (locationReference, data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT}/${locationReference}/locationnewregister`, { ...data }).catch((error) => {
    errorResponse = error.toString();
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEnergyLabel = async (locationReference, id) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/label/${id}`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchEnergyLabel = async (locationReference, id, data) => {
  console.log("patchEnergyLabel, locationReference, id, data: ", locationReference, id, data);
  return patchRequest(`${END_POINT}/${locationReference}/label/${id}`, data);
};

const postEnergyLabel = async (locationReference, data) => {
  let errorResponse = undefined;

  let response = await apiClient.post(`${END_POINT}/${locationReference}/label`, { ...data }).catch((error) => {
    errorResponse = error.toString();
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationPartyDetails = async (locationReference, id) => {
  let errorResponse = undefined;
  let response = await apiClient.get(`${END_POINT}/${locationReference}/partyaddress/${id}/details`).catch((error) => {
    errorResponse = error.toString();
  });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const patchPartyAddressDetails = async (locationReference, detailId, data) => {
  let errorResponse = undefined;
  let apiError = undefined;
  const response = await apiClient.patch(`${END_POINT}/${locationReference}/partyaddress/${detailId}`, { ...data }).catch((error) => {
    apiError = error;
  });

  if (apiError != undefined) {
    switch (apiError.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = apiError.toString();
    }
  } else {
    switch (response.status) {
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geupdate";
        break;
    }
  }

  return { data: response ? response.data : undefined, error: errorResponse };
};

const postPartyAddressDetails = async (locationReference, data) => {
  let errorResponse = undefined;
  let response = await apiClient.post(`${END_POINT}/${locationReference}/partyaddress`, { ...data }).catch((error) => {
    switch (error.response?.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 409: // conflict
        errorResponse = "Er is een conflict op de tijdlijn";
        break;
      default:
        errorResponse = error.toString();
    }
  });
  if (response) {
    switch (response.status) {
      // todo voor codes moet const httpConstants = require('http2').constants gebruikt worden maar krijg het nog niet werkend
      case 204: // no-content
        errorResponse = "Er zijn geen gegevens geinsert";
        break;
      default:
    }
  }
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getLocationRegistersOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/registersoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEnergyLabelsOverview = async (reference, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  let errorResponse = undefined;
  const response = await apiClient
    .post(`${END_POINT}/${reference}/labelsoverview`, { filterData: filterData }, { params: { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc } })
    .catch((error) => {
      errorResponse = error.toString();
    });
  return { data: response ? response.data : undefined, error: errorResponse };
};

const getEPonlineOverview = async (bagId, postalCode, houseNumber, houseNumberAddition) => {
  const response = getRequest(`${END_POINT}/eponlineoverview`, { bagId: bagId, postalCode: postalCode, houseNumber: houseNumber, houseNumberAddition: houseNumberAddition });
  return response;
};

const getBagOverview = async (bagId, postalCode, houseNumber, houseNumberAddition) => {
  return getRequest(`${END_POINT}/bagoverview`, { bagId: bagId, postalCode: postalCode, houseNumber: houseNumber, houseNumberAddition: houseNumberAddition });
};

const connectBagObjects = async (reference, selectedBagObjects, validity) => {
  console.log("location.api, connectBagObjects, : ", reference, selectedBagObjects, validity);
  return postRequest(`${END_POINT}/${reference}/sublocation/bag`, {
    bagData: selectedBagObjects,
    validFrom: validity.validFrom,
    validTill: validity.validTill,
    modifiedDescription: validity.modifiedDescription,
  });
};

const connectLocations = async (reference, sublocationReference, validity) => {
  console.log("location.api, connectLocations, : ", reference, sublocationReference, validity);
  return postRequest(`${END_POINT}/${reference}/sublocation`, {
    locationReferences: [sublocationReference],
    validFrom: validity.validFrom,
    validTill: validity.validTill,
    modifiedDescription: validity.modifiedDescription,
  });
};


const deleteEnergyLabelById = async (locationReference, labelId) => {
  console.log(`labelId :`, labelId);
  return deleteRequest(`${END_POINT}/${locationReference}/label/${labelId}`)
}

export {
  getLocationOverview,
  getLocationOverviewFilterOptions,
  getLocation,
  getLocationDetails,
  getLocationChildDetails,
  patchLocationChilds,
  getLocationDetailByReference,
  getLocationDetail,
  getLocationPartyOverview,
  getAddressesOverview,
  getAddressesOverviewChilds,
  patchLocationDetails,
  postLocationDetails,
  postLocationWizard,
  getLocationDiagram,
  getLocationRegisterDetails,
  patchLocationRegisterDetails,
  postLocationRegisterDetails,
  postLocationNewRegisterDetails,
  getLocationRegistersOverview,
  getEnergyLabel,
  patchEnergyLabel,
  postEnergyLabel,
  getEnergyLabelsOverview,
  getLocationPartyDetails,
  patchPartyAddressDetails,
  postPartyAddressDetails,
  getEPonlineOverview,
  getBagOverview,
  connectBagObjects,
  connectLocations,
  severityCode,
  deleteEnergyLabelById,
};
