<script lang="ts" setup>
import { ref, computed } from "vue";
import { mdiAccountGroup, mdiClipboardTextMultipleOutline, mdiScriptTextOutline } from "@mdi/js";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const authStore = useAuthStore();

const icons = { mdiAccountGroup, mdiClipboardTextMultipleOutline, mdiScriptTextOutline };

const drawerLeft = ref(false);
const drawerRight = ref(false);

const userIsAdmin = computed(() => userStore.isAdministrator);
const allowEnergyUsage = computed(() => userStore.hasUpdateEnergyUsage);
const allowImportExcel = computed(() => userStore.hasPermissionImportExcel);
const allowImportVabi = computed(() => userStore.hasPermissionImportVabi);

function logout() {
  authStore.logout();
}
</script>
<template>
  <v-layout>
    <v-app-bar app color="white" class="navbar">
      <v-app-bar-nav-icon @click="drawerLeft = true" class="d-flex d-md-none"></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <router-link :to="{ name: 'Dashboard' }">
          <v-img alt="INNAX Logo" class="shrink mr-2" contain src="@/assets/img/logo.png" width="140" />
        </router-link>
      </div>
      <v-layout class="d-none d-md-flex">
        <v-btn text :to="{ name: 'Dashboard' }">DataHub</v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on"
              >Pandenlijst
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list class="navbar">
            <v-list-item :to="{ name: 'Locations' }">
              <v-list-item-icon><v-icon>mdi-crosshairs</v-icon> </v-list-item-icon>
              <v-list-item-title>Locaties</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Parties' }">
              <v-list-item-icon><v-icon>mdi-domain</v-icon> </v-list-item-icon>
              <v-list-item-title>Partijen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on"
              >Maatregelen
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list class="navbar">
            <v-list-item :to="{ name: 'Measures' }">
              <v-list-item-icon><v-icon>mdi-language-markdown-outline</v-icon></v-list-item-icon>
              <v-list-item-title>Definities</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y v-if="allowEnergyUsage">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on"
              >Energiebeheer
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list class="navbar">
            <v-list-item text :to="{ name: 'EU-Registers' }">
              <v-list-item-icon><v-icon>mdi-speedometer</v-icon></v-list-item-icon>
              <v-list-item-title>Meters</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on"
              >Identiteits- en toegangsbeheer
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list class="navbar">
            <v-list-item-group>
              <v-list-item :to="{ name: 'IAM-UsersOverview' }">
                <v-list-item-icon><v-icon>mdi-account-multiple</v-icon></v-list-item-icon>
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'IAM-UserGroupsOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ icons.mdiAccountGroup }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item :to="{ name: 'IAM-ResourcesOverview' }">
                <v-list-item-icon><v-icon>mdi-apps</v-icon></v-list-item-icon>
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'IAM-ProjectsOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ icons.mdiClipboardTextMultipleOutline }} </v-icon></v-list-item-icon
                >
                <v-list-item-title>Projecten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item :to="{ name: 'IAM-RolesOverview' }">
                <v-list-item-icon
                  ><v-icon>{{ icons.mdiScriptTextOutline }}</v-icon></v-list-item-icon
                >
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-menu v-if="userIsAdmin" offset-y>
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn text v-bind="attrs" v-on="on"
              >Beheer
              <v-icon v-if="value" right>mdi-chevron-up</v-icon>
              <v-icon v-else right>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list class="navbar">
            <v-list-item :to="{ name: 'MD-Dashboard' }">
              <v-list-item-icon><v-icon>mdi-format-list-bulleted</v-icon> </v-list-item-icon>
              <v-list-item-title>Master data</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="allowImportExcel" :to="{ name: 'ImportData' }">
              <v-list-item-icon><v-icon>mdi-import</v-icon> </v-list-item-icon>
              <v-list-item-title>Importeren</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="allowImportVabi" :to="{ name: 'ImportVabiData' }">
              <v-list-item-icon><v-img contain src="@/assets/img/logo-vabi.png" alt="VABI" /></v-list-item-icon>
              <v-list-item-title>Vabi woningen importeren</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="{ name: 'Info' }">
              <v-list-item-icon><v-icon>mdi-information-outline</v-icon> </v-list-item-icon>
              <v-list-item-title>Info</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>

      <v-spacer></v-spacer>
      <v-layout justify-end class="d-none d-md-flex">
        <v-menu offset-y origin="top right">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              {{ userStore.getUserFullname }}
            </v-btn>
          </template>
          <v-list class="navbar">
            <v-list-item link @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Afmelden</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>

      <v-app-bar-nav-icon @click="drawerRight = true" class="d-flex d-md-none"><v-icon>mdi-account-outline</v-icon></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawerLeft" absolute temporary>
      <v-list nav>
        <v-list-item :to="{ name: 'Dashboard' }">
          <v-list-item-title>DataHub</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Pandenlijst</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'Locations' }">
            <v-list-item-title>Locaties</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Parties' }">
            <v-list-item-title>Partijen</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Maatregelen</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'Measures' }">
            <v-list-item-title>Definities</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-group :value="false" v-if="allowEnergyUsage">
          <template v-slot:activator>
            <v-list-item-title>Energiebeheer</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'EU-Registers' }">
            <v-list-item-title>Meters</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Beheer</v-list-item-title>
          </template>
          <v-list-item :to="{ name: 'MD-Dashboard' }">
            <v-list-item-title>Master data</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowImportExcel" :to="{ name: 'ImportData' }">
            <v-list-item-title>Importeren</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="allowImportVabi" :to="{ name: 'ImportVabiData' }">
            <v-list-item-title>Vabi woningen importeren</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Info' }">
            <v-list-item-title>Info</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="drawerRight" absolute temporary right>
      <v-list nav>
        <v-list-item-title class="ma-2"> {{ userStore.getUserFullname }} </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Afmelden</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer app fixed>
      <v-container fluid pa-0>
        <v-row>
          <v-col align="center" class="pa-1"> &copy; {{ new Date().getFullYear() }} - Consolidated Location List </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-layout>
</template>
