import axios from "axios";
import { useAuthStore } from "@/stores/auth";

// api ingang
let apiClient;

const severityCode = {
  none: -1,
  hint: 0,
  warning: 1,
  error: 2,
  fatal: 3,
};

//todo severity in global enum

export default {
  install(Vue, options) {
    //console.log("httpClient created", options);
    apiClient = axios.create({
      baseURL: `${options.portalUrl}api/`,
      timeout: 20000,
      // timeout: 5000,
      headers: {
        "Content-Type": "application/json, multipart/form-data",
        "X-Requested-With": "XMLHttpRequest",
        // anything you want to add to the headers
      },
    });

    apiClient.interceptors.request.use(async (config) => {
      //console.log("httpClient, authInterceptor, token ");
      const authStore = useAuthStore();
      const token = await authStore.getAccessToken();
      //console.log('httpClient, authInterceptor, token: ', token)
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
  },
};

//todo code zou eigen code moeten zijn en niet de httpstatuscode
const getRequest = async (url, params) => {
  let message = undefined;
  let code = undefined;
  let severity = severityCode.none;
  let response = await apiClient.get(url, { params: params }).catch((error) => {
    //console.log("httpClient getRequest error ", error);
    message = error.toString();
    severity = severityCode.error;
    code = error.response?.status;
  });
  //console.log("httpClient getRequest response ", response);
  if (message == undefined) {
    switch (response.status) {
      case 204: // no-content
        message = "Er zijn geen gegevens";
        severity = severityCode.warning;
        code = response.status;
        break;
      case 290: // too many results
        message = "Teveel resultaten";
        severity = severityCode.warning;
        code = response.status;
        break;
    }
  }
  return { data: response ? response.data : undefined, message: message, severity: severity, code: code };
};

const postRequest = async (url, data, params) => {
  let message = undefined;
  let code = undefined;
  let severity = severityCode.none;
  const response = await apiClient.post(url, data, { params: params, timeout: 90000 }).catch((error) => {
    //console.log("httpClient postRequest error ", error);
    message = error.toString();
    severity = severityCode.error;
    code = error.response?.status;
    if (error.response) {
      switch (error.response?.status) {
        case 409:
          message = "conflict";
          break;
        default:
          message = "Network exception";
      }
    }
  });

  if (message == undefined) {
    code = response.status;
    switch (response.status) {
      case 204: // no-content
        message = "Er zijn geen gegevens geupdate";
        severity = severityCode.warning;
        break;
    }
  }
  return { data: response ? response.data : undefined, message: message, severity: severity, code: code };
};

const putRequest = async (url, data, params) => {
  let message = undefined;
  let severity = severityCode.none;
  const response = await apiClient.put(url, data, { params: params }).catch((error) => {
    message = error.toString();
    severity = severityCode.error;
  });

  if (message == undefined) {
    switch (response.status) {
      case 204: // no-content
        message = "Er zijn geen gegevens geupdate";
        severity = severityCode.warning;
        break;
    }
  }
  return { data: response ? response.data : undefined, message: message, severity: severity };
};

const patchRequest = async (url, data, params) => {
  let message = undefined;
  let severity = severityCode.none;
  const response = await apiClient.patch(url, data, { params: params }).catch((error) => {
    message = error.toString();
    severity = severityCode.error;
  });

  if (message == undefined) {
    switch (response.status) {
      case 204: // no-content
        message = "Er zijn geen gegevens geupdate";
        severity = severityCode.warning;
        break;
    }
  }
  return { data: response ? response.data : undefined, message: message, severity: severity };
};

const deleteRequest = async (url) => {
  let message = undefined;
  let severity = severityCode.none;
  const response = await apiClient.delete(url).catch((error) => {
    message = error.toString();
    severity = severityCode.error;
  });

  if (message == undefined) {
    switch (response.status) {
      case 204: // no-content
        message = "Er zijn geen gegevens geupdate";
        severity = severityCode.warning;
        break;
    }
  }
  return { data: response ? response.data : undefined, message: message, severity: severity };
};

export { apiClient, getRequest, postRequest, putRequest, patchRequest, deleteRequest, severityCode };

// interceptor to catch errors
// const errorInterceptor = error => {
//     // check if it's a server error
//     if (!error.response) {
//         toaster.warning('Network/Server error');
//         return Promise.reject(error);
//     }

//     // all the other error responses
//     switch (error.response.status) {
//         case 400:
//             console.error(error.response.status, error.message);
//             //router.app.$toaster.warn('Nothing to display','Data Not Found');
//             break;

//         case 401: // authentication error, logout the user
//             //$toaster.warn( 'Please login again', 'Session Expired');
//             break;

//         default:
//             console.error(error.response.status, error.message);
//         //$toaster.error('Server Error');

//     }
//     return Promise.reject(error);
// }

// Interceptor for responses
// const responseInterceptor = response => {
//     switch (response.status) {
//         case 200:
//             // yay!
//             break;
//         // any other cases
//         default:
//         // default case
//     }

//     return response;
// }

// httpClient2.interceptors.request.use(authInterceptor);

//httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
